import store from "@/store";
import {  buildSelectedAccesory, buildSelectedGood, buildSelectedOption, buildSelectedOther, catalogList, getSelectedGood, saveGoods, searchAsset, SavePackItemProduct, saveProposalItem, getGoodProposalItem, getAssetProposelItem, filterCatalog, saveCatalog, getCatalogsBYProduct } from "@/store/services/configuration/AssetService";
import { AssetsState, GoodDetails, GoodResidualValue } from "@/types";
import { deepClone, mountSelectFields } from "@/utils";
import { Module } from "vuex";
import { catalogMock } from "@/store/services/configuration/AssetService";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
const assetModule: Module<AssetsState, unknown> = {
    namespaced: true,
    state: {
        openAsset: false,
        isLoading: false,
        selectedAsset: {

            name: "",
            validity: {
                from: "",
                until: ""
            },
            status: {
                type: {
                    value: ''
                }
            },
            type: {
                type: {
                    value: ''
                }
            },
            tax: {
                type: {
                    value: ''
                }
            },
            objectType: "",
            businessData: {},
            daaq: "/",
            feature: [],
            brand: {
                id: '',
                label: '',
                config: {}
            },
            version: {
                id: '',
                label: '',
                config: {}
            },
            family: {
                id: '',
                label: '',
                config: {}
            },
            subFamily: {
                id: '',
                label: '',
                config: {}
            },
            range: {
                id: '',
                label: '',
                config: {}
            },
            carBodyStyle: {
                id: '',
                label: '',
                config: {}
            },
            engineType: {
                id: '',
                label: '',
                config: {}
            },
            goodOption: [],
            goodAccessory: [],
            goodFees: [],
            residualValue: {
                minRv: 0,
                maxRv: 0,
                defaultRv: 0,
                advancedConfigurationToggle: false
            },
            classifications: [],
            goodCatalogs: {},
            selectedGoods: [],
            selectedCatalogs: [],
            inventory:false,
            guarantee: {
                from: "",
                until: ""
            },
            serialNumber:'',
            constructionDate:'',
            documents: []

            

        },
        selectedItems: [],
        tempSelectedGoods: [],
        selectedCatalogs: [],
        tempSelectedCatalogs: [],
        validField: true,
        
    },
    getters: {
        getselectedAsset: (state): GoodDetails | undefined => {
            return state.selectedAsset;
        },
        getGoodsInit() {
            return {
                name: "",
                validity: {
                    from: "",
                    until: ""
                },
                status: {
                    type: {
                        value: ''
                    }
                },
                type: {
                    type: {
                        value: ''
                    }
                },
                tax: {
                    type: {
                        value: ''
                    }
                },
                objectType: "",
                businessData: {},
                daaq: "/",
                feature: [],
                brand: {
                    id: '',
                    label: '',
                    config: {}
                },
                family: {
                    id: '',
                    label: '',
                    config: {}
                },
                subFamily: {
                    id: '',
                    label: '',
                    config: {}
                },
                version: {
                    id: '',
                    label: '',
                    config: {}
                },
                range: {
                    id: '',
                    label: '',
                    config: {}
                },
                carBodyStyle: {
                    id: '',
                    label: '',
                    config: {}
                },
                engineType: {
                    id: '',
                    label: '',
                    config: {}
                },
                goodOption: [],
                goodAccessory: [],
                goodFees: [],
                residualValue: {
                    advancedConfigurationToggle: false
                },

                goodCatalogs: {},
                selectedGoods: [],
                selectedCatalogs: [],
                inventory:false,
                guarantee: {
                    from: "",
                    until: ""
                },
                serialNumber:'',
                constructionDate:''

            }

        },
        getGoodCatalogCreationInit() {
            return {
                reference: "",
                description: "",
                validity: {
                    from: "",
                    until: "",
                },
                supplier: {
                    organization: {
                        name: ""
                    }
                }
            }
        },
        getGoodResidualValue() {
            return {
                defaultResidualValue: 0,
                minResidualValue: 0,
                maxResidualValue: 0
            }
        },
        getProposalGoodItemInit() {
            return {
                index: 0,
                nameItem: '',
                required: false,
                priceWoTax: 0,
                discount: 0,
                tax: 0,
                typeTax: {
                    label: "",
                    value: ""
                }
            }
        },
        /**
         * Initialize the GoodClassification.
         * This method returns an initial object with empty properties for category, categoryClass, and validity date range.
         * @return {ClassificationsAsset} An initial object for asset classification information.
         */
        getAssetClassificationInit() {
            return {
                businessData: null,
                daaq: "/",
                validity: {
                    from: '',
                    until: ''
                },
                categoryClass: {
                    objectType: "odm.product.productcategoryclass",
                    systemUid: "odm-product",
                    resourceUid: ''
                },
                priority: null,
                category: {
                    objectType: "odm.product.productcategory",
                    systemUid: "odm-product",
                    resourceUid: ''
                }
            }
        },

        /**
        * Initialize the general information for assets.
        * This method returns an initial object with empty properties for selected files, reference, description,
        * and validity date range.
        * @return {Object} An initial object for asset general information.
        */
        getAssetGeneralInfoInit() {
            return {
                selectedFiles: [],
                reference: "",

                description: "",

                validity: {
                    from: "",

                    until: "",
                },
            };
        },


        /**
        * Initialize the general information for assets.
        * This method returns an initial object with empty properties for selected files, reference, description,
        * and validity date range.
        * @return {Object} An initial object for asset general information.
        */
        getProposalItemInfoInit() {
            return {
                selectedFiles: [],
                reference: "",
                mandatory: false,
                description: "",
                validity: {
                    from: "",
                    until: "",
                },
                residualValue :{
                    advancedConfigurationToggle: false,
                    priceWoTax:""
                }
            };
        },



        /**
        * Initialize the price details for a Good Asset.
        *
        * This method returns an initial object with empty properties for currency, price, taxeType, remise, and validity date range.
        *
        * @return {Object} An initial object for price details information.
        */
        getAssetPriceDetailsInit() {
            return {

                objectType: "odm.product.featurepricingparameter",
                systemUid: "odm-product",
                businessData: null,
                daaq: "/",
                defaultValue: false,
                label: "AMOUNT",
                internalCode: "AMOUNT",
                name: "AMOUNT",
                standardValue: 0,
                minValue: 0,
                maxValue: 0,
                currency: "EUR",
                basis: null,
                validity: {
                    from: "",
                    until: "",
                },

            }

        },

        getGoodFeesServicesInit() {
            return {

                reference: "",
                validity: {
                    from: "",
                    until: "",
                },
                description: "",
                required: false,
                typeTax: "",
            };
        },
        getAssetDocumentInit() {
            return {
                file:{},
                creationDate:'',
                addedBy:'',
                content:'',
                format:'',
                originalFileName:'',
              documentType: {
                type: {
                  value: '',
                  label: '',
                }
              }
            }
          }
    },
    actions: {
        /**
         * Action that change the value of ValidField
         *
         * @param {object} context - The context object for ValidField.
         * @param {object} payload - The payload object with new value of ValidField.
         * @return {void}
         */
        changeValidField({ commit }, payload) {
          commit("changeValidField", payload);
        },
        async setGoodProposalItemFields({ commit }, payload) {
            const { resourceUid, index, type } = payload
            const goodProposalItem = await getGoodProposalItem(resourceUid)
            commit('setGoodProposalItemFields', { resourceUid, index, type, goodProposalItem })
        },
        toggleIsLoading({ commit }) {
            commit('toggleIsLoading')
        },
        async setProposalGoodItem({ commit }, payload) {
            const { type, proposalAssetItem } = payload
            const proposalItem = await saveProposalItem(proposalAssetItem, type)
            proposalAssetItem.resourceUid = proposalItem.resourceUid
            proposalAssetItem.nameItem.id = proposalItem.resourceUid
            proposalAssetItem.classifications = proposalItem.classifications
            commit('addNewProposalGoodItem', { type, proposalAssetItem });

        },
        initClassifCombo({ commit }, payload) {
            commit('initClassifCombo', payload)
        },
        resetGoods({ commit }, payload) {
            commit("resetSelectedAsset", payload)
            commit("resetSelectedItems", [])
            commit("resetTempSelectedGoods", [])
            commit("resetTempSelectedCatalogs", [])
            commit("setMode", "")
        },
        saveGood({ commit }, payload) {
            commit('toggleIsLoading')
            const { goodDetail, callback } = payload;
            const goodDetails = deepClone(goodDetail);
            saveGoods(goodDetails).then((res: any) => {
                getSelectedGood(res.resourceUid).then(async good => {
                    const goodSelected = await buildSelectedGood(good)
                    const optionSelected = await buildSelectedOption(good)
                    const accesorySelected = await buildSelectedAccesory(good)
                    const otherSelected = await buildSelectedOther(good)
                    const catalogs = await getCatalogsBYProduct(good)
                    commit('setGoodSelected', goodSelected)
                    commit('setPackResourceUID', res.resourceUid)
                    commit('setProposalOptionSelected', optionSelected)
                    commit('setProposalAccessorySelected', accesorySelected)
                    commit('updateSelectedCatalogs', catalogs);
                    commit('setProposalOtherSelected', otherSelected)
                })
                Notify.create({
                    message: i18n.global.t('main.dialog.success', { entity: res.name }),
                    color: 'positive'
                })
                commit('toggleIsLoading')
            }).catch(err => {
                // Logs the error to the console.
                console.error(err);
                Notify.create({
                    message: i18n.global.t('main.dialog.error'),
                    color: 'negative'
                })
                commit('toggleIsLoading')
            })
        },

      setProviderName({ commit }, payload) {
        commit("setProviderName", payload);
    },

        /**
         * Searches for assets based on the given filter.
         * @param {object} context - The context object.
         * @param {object} payload - The payload object containing the filter and a callback function.
         * @return {void}
         */
        searchAsset(context, payload) {
            // Gets the callback function from the payload.
            const { filter,offset,limit, callback } = payload;
            // Calls the `assetList()` function to fetch the list of assets.
            searchAsset(filter,offset,limit)
                .then((response) => {
                    // Passes the response to the callback function.
                    callback(response);
                })
                .catch((err) => {
                    // Logs the error to the console.
                    console.error(err);
                });
        },
       

        executeSelectGood({ commit }, payload) {
            const { id, callback } = payload;
            getSelectedGood(id).then(async good => {
                const goodSelected = await buildSelectedGood(good)
                const optionSelected = await buildSelectedOption(good)
                const accesorySelected = await buildSelectedAccesory(good)
                const otherSelected = await buildSelectedOther(good)
                const catalogs = await getCatalogsBYProduct(good)
                commit('setGoodSelected', goodSelected)
                commit('setProposalOptionSelected', optionSelected)
                commit('setProposalAccessorySelected', accesorySelected)
                commit('setProposalOtherSelected', otherSelected)
                commit('updateSelectedCatalogs', catalogs);
                commit('setPackResourceUID', id)

                if (callback) {
                    callback(good)
                }
            }).catch(err => {
                Notify.create({
                    message: `Error searching Good \n ${err}`,
                    color: 'negative'
                });
            })
        },


        /**
         * Searches for catalogs based on the given filter.
         * @param {object} context - The context object.
         * @param {object} payload - The payload object containing the filter and a callback function.
         * @return {void}
         */
        

        /**
         * Action to set the 'goodCatalogCreation' property in the state's selectedAsset object.
         * This action commits the 'setgoodCatalogCreation' mutation with the provided 'payload' to update the 'goodCatalogCreation' property within the selectedAsset's 'goodCatalogs' object.
         * @param {object} context - The Vuex context object, which provides access to commit and other state management functions.
         * @param {GoodCatalogCreation} payload - The data to assign to the 'goodCatalogCreation' property.
         */
        async setgoodCatalogCreation({ commit }, payload) {
            const {ProductCatalogItem } = payload;
            await saveCatalog(ProductCatalogItem).then((res: any) => {
                const selectedCatalogs = store.state.assetModule.selectedAsset?.selectedCatalogs ? store.state.assetModule.selectedAsset?.selectedCatalogs : [];
                selectedCatalogs.push(res);
                store.dispatch("assetModule/updateSelectedCatalogs",  selectedCatalogs);
                Notify.create({
                  message: i18n.global.t("good.dialog.Addsuccess"),
                  color: 'positive'
                })
              }).catch(err => {
            Notify.create({
                  message: i18n.global.t("good.dialog.catalogExistsError"),
                  color: 'negative'
                })
              })            
                  },
             setInitgoodCatalogCreation({ commit }, payload) {
                    const {catalog } = payload;
                    commit("setgoodCatalogCreation", catalog );   
               },       
        /**
         * Action to set good classifications in the state.
         * This action pushes the payload into the selectedAsset's goodClassifications array.
         * @param {ClassificationsAsset} payload - The data to push into the selectedAsset's goodClassifications.
         */
        setgoodClassifications({ commit }) {
            

            commit("setGoodClassifications");
        },
        /**
         * Action to remove a good classification from the state.
         * This action commits the "removeGoodClassifications" mutation to remove the classification at the specified index.
         * @param {Object} payload - The payload containing the index of the classification to remove.
         */
        removeGoodClassifications({ commit }, payload) {
            commit("removeGoodClassifications", payload)
        },
        addNewProposalGoodItem({ commit }, payload) {
            const { type } = payload
            const proposalAssetItem = deepClone(store.getters["assetModule/getProposalGoodItemInit"])
            commit('addNewProposalGoodItem', { type, proposalAssetItem });



        },
        removeProposalGoodItem({ commit }, payload) {

            commit('removeGoodItem', payload)

        },



        /**
         * Action to add price details to a Good Asset.
         *
         * @param {Object} commit - The Vuex commit function.
         * @param {Object} payload - The payload containing price details to be added.
         */
        PriceDetailsAction({ commit }, payload) {
            commit("setPriceDetails", payload);
        },
        /**
         * Action to remove asset price details from the state.
         * This action commits the "removeAssetPriceDetails" mutation to remove the price details at the specified index.
         * @param {Object} payload - The payload containing the index of the price details to remove.
         */
        removeAssetPriceDetailsAction({ commit }, payload) {
            commit("removeAssetPriceDetails", payload)
        },

        /**
       * Action to update the item information.
       * This action commits the "setProposalItemInfo" mutation with the provided `payload`.
       * @param {Object} payload - The new general information.
       */
        initItemProposalState({ commit }, payload) {

            commit("setProposalItemInfo", payload);

        },
        /**
        * Action to update the list of selected files.
        * This action commits the "setFiles" mutation with the provided `payload`.
        * @param {Array} payload - The new list of selected files.
        */
        setFilesAction({ commit }, payload) {
            commit("setFiles", payload);
        },

       

        /**
        * Action to update the list of selected files.
        * This action commits the "setFiles" mutation with the provided `payload`.
        * @param {Array} payload - The new list of selected files.
        */
        setFilesItemAction({ commit }, payload) {
            commit("setItemFiles", payload);
        },
        setGoodResidualValue({ commit }, payload) {
            commit("setGoodResidualValue", payload);
        },
        goodFeesServicesAction({ commit }, payload) {
            commit("setGoodFeesServices", payload);
        },
        /**
         * Action to update the list of selected goods catalogs.
         * This action commits the "setGoodCatalogs" mutation with the provided `payload`.
         * @param {Object} context - The Vuex action context.
         * @param {any} payload - The data to set the goods catalogs.
         */
        setGoodCatalogsAction({ commit }, payload) {
            commit("setGoodCatalogs", payload);
        },
        /**
         * Action to remove the list of selected goods catalogs.
         * This action commits the "removeGoodCatalogs" mutation to clear the goods catalogs.
         * @param {Object} context - The Vuex action context.
         */
        removeGoodCatalogsAction({ commit }) {
            commit("removeGoodCatalogs");
        },
        /**
         * Action to simulate searching for catalogs using a mock API.
         * This action initiates a search request to a mock API and returns the response using a callback.
         * @param {Object} context - The Vuex action context.
         * @param {Object} payload - The payload object containing a callback function to handle the response.
         *                          payload.callback - The callback function to handle the response.
         */
        searchCatalog({commit}, payload) {
            const { filter, callback } = payload
            filterCatalog(filter)
                .then(res => {
                    callback(res)
                })
                .catch(e => console.error(e))
        },
        updateSelectedItems({ commit }, newSelected) {
            commit("SET_SELECTED_ITEMS", newSelected);
        },
        assetProposelItemCustom({ commit }, payload) {
            const { filter, callback } = payload
            getAssetProposelItem(filter)
                .then(res => {
                    callback(res.items);
                })
                .catch(e => console.error(e))
        },
        /**
         * Updates the selected goods in the store.
         *
         * @param {object} commit - The Vuex commit function.
         * @param {Array<AssetSearch>} selectedGoods - The list of selected goods.
         */
        updateSelectedGoods({ commit }, selectedGoods) {
            const existingSelectedGoods = store.state.assetModule.selectedAsset?.selectedGoods;
            if (existingSelectedGoods !== undefined && existingSelectedGoods.length > 0) {
                existingSelectedGoods.forEach((asset: any) => {
                    if (!existingSelectedGoods.includes(asset)) {
                        selectedGoods.push(asset);
                    }
                });
            }
            commit('updateSelectedGoods', selectedGoods);
        },
        removeGood({ commit }, reference) {
            const selectedGoods = store.state.assetModule.selectedAsset?.selectedGoods;
            if (!selectedGoods) {
                return;
            }
            const filteredGoods = selectedGoods.filter((good) => good.reference !== reference);
            commit('updateSelectedGoods', filteredGoods);
            commit('updateTempSelectedGoods', filteredGoods);
        },
        /**
         * Updates the saved assets in the store.
         *
         * @param {object} commit - The Vuex commit function.
         * @param {Array<AssetSearch>} tempSelectedGoods - The list of saved assets.
         */
        updateTempSelectedGoods({ commit }, tempSelectedGoods) {
            commit('updateTempSelectedGoods', tempSelectedGoods);
        },
        /**
         * Updates the selected catalogs in the store.
         *
         * @param {object} commit - The Vuex commit function.
         * @param {Array<AssetSearch>} selectedCatalogs - The list of selected catalogs.
         */
        updateSelectedCatalogs({ commit }, selectedCatalogs) {
            const existingSelectedCatalogs = store.state.assetModule.selectedAsset?.selectedCatalogs;
            if (existingSelectedCatalogs !== undefined && existingSelectedCatalogs.length > 0) {
                existingSelectedCatalogs.forEach((asset: any) => {
                    if (!existingSelectedCatalogs.includes(asset)) {
                        selectedCatalogs.push(asset);
                    }
                });
            }
            commit('updateSelectedCatalogs', selectedCatalogs);
        },
        removeCatalog({ commit }, reference) {
            const selectedCatalogs = store.state.assetModule.selectedAsset?.selectedCatalogs;
            if (!selectedCatalogs) {
                return;
            }
            const filteredCatalogs = selectedCatalogs.filter((catalog) => catalog.reference !== reference);
            commit('updateSelectedCatalogs', filteredCatalogs);
            commit('updateTempSelectedCatalogs', filteredCatalogs);
        },
        /**
         * Updates the temp catalogs in the store.
         *
         * @param {object} commit - The Vuex commit function.
         * @param {Array<GoodCatalogsSearch>} tempSelectedCatalogs - The list of temp catalogs.
         */
        updateTempSelectedCatalogs({ commit }, tempSelectedCatalogs) {
            commit('updateTempSelectedCatalogs', tempSelectedCatalogs);
        }
    },
    mutations: {
        setGoodProposalItemFields(state, payload) {
            const { resourceUid, index, type, goodProposalItem } = payload
            const selectedAssetType = (state.selectedAsset as any)[type];
            if (state.selectedAsset && selectedAssetType) {
                if (goodProposalItem.feature.length) {
                    selectedAssetType[index].priceWoTax = goodProposalItem.feature[0].standardValue
                    selectedAssetType[index].required = goodProposalItem.feature[0].defaultValue
                }
                selectedAssetType[index].resourceUid = resourceUid
                if (goodProposalItem.tax !== null && goodProposalItem.tax.resourceUid !== null) {
                    selectedAssetType[index].typeTax.value = goodProposalItem.tax.resourceUid
                    selectedAssetType[index].typeTax.label = goodProposalItem.tax.resourceUid
                }
                else {
                    selectedAssetType[index].typeTax.value = ""
                    selectedAssetType[index].typeTax.label = ""
                }
            }
        },
        toggleIsLoading(state) {
            state.isLoading = !state.isLoading
        },
        resetSelectedAsset(state, payload) {
            state.selectedAsset = payload
        },
        resetSelectedItems(state, payload) {
            state.selectedItems = []
        },
        resetTempSelectedGoods(state) {
            state.tempSelectedGoods = []
        },
        resetTempSelectedCatalogs(state) {
            state.tempSelectedCatalogs = []
        },
        setMode(state, payload) { 
            state.mode = payload
        },
        setTempSelectedCatalogs (state, payload) {
            if (state.selectedAsset && state.selectedAsset.selectedCatalogs) {
                state.selectedAsset.selectedCatalogs = payload;
            }
        },
        /**
         * Set the 'goodCatalogCreation' property in the state's selectedAsset object.
         * This mutation is responsible for updating the 'goodCatalogCreation' property within the selectedAsset's 'goodCatalogs' object. It assigns the provided 'payload' to this property.
         * @param {AssetsState} state - The state object of the AssetModule.
         * @param {GoodCatalogCreation} payload - The data to assign to the 'goodCatalogCreation' property.
        */
        setgoodCatalogCreation(state, payload) {
            if (state.selectedAsset?.goodCatalogs)
                state.selectedAsset.goodCatalogs.goodCatalogCreation = payload
        },
        /**
         * Set good classifications in the state.
         * This mutation pushes the payload into the selectedAsset's goodClassifications array.
         * @param {AssetsState} state - The state object of AssetModule.
         * @param {ClassificationsAsset} payload - The data to push into the selectedAsset's goodClassifications.
         */
        setGoodClassifications(state) {
            const getter = deepClone(store.getters["assetModule/getAssetClassificationInit"])
            state.selectedAsset?.classifications?.push(getter);
        },
        /**
         * Mutation to remove a good classification from the state.
         * This mutation removes the classification at the specified index from the selectedAsset's goodClassifications array.
         * @param {AssetsState} state - The state object of AssetModule.
         * @param {Object} payload - The payload containing the index of the classification to remove.
         */
        removeGoodClassifications(state, payload) {
            const { index } = payload
            state.selectedAsset?.classifications?.splice(index, 1);
        },



        addNewProposalGoodItem(state, payload) {

            const { proposalAssetItem, type } = payload;

            (state.selectedAsset as any)[type].push(proposalAssetItem)
        },
        removeGoodItem(state, payload) {
            const { index, type } = payload;
            (state.selectedAsset as any)[type].splice(index, 1);
        },

        initClassifCombo(state, payload) {
            (state.selectedAsset as any)[payload]= null 
        },

        setProposalOptionSelected(state, payload) {
            if (state.selectedAsset && state.selectedAsset.goodOption) {
                state.selectedAsset.goodOption = payload;
            }
        },
        setProposalAccessorySelected(state, payload) {
            if (state.selectedAsset && state.selectedAsset.goodAccessory) {
                state.selectedAsset.goodAccessory = payload;
            }
        },
        setProposalOtherSelected(state, payload) {
            if (state.selectedAsset && state.selectedAsset.goodFees) {
                state.selectedAsset.goodFees = payload;
            }
        },


        setProviderName(state, payload) {
            if (state.selectedAsset?.goodCatalogs?.goodCatalogCreation) {
                
                if (payload && payload.objectType) {
                    state.selectedAsset.goodCatalogs.goodCatalogCreation.provider = {
                      objectType: payload.objectType,
                      systemUid: payload.systemUid,
                      resourceUid: payload.resourceUid
                    };
            }

        }

    },
        


        /**
         * Mutate and set the price details of a selected Good Asset.
         *
         * @param {Object} state - The Vuex state.
         * @param {Object} payload - The payload containing price details to be added.
         */
        setPriceDetails(state, payload) {
            if (state.selectedAsset) { state.selectedAsset.feature?.push(payload); }
        },
        /**
        * Mutation to remove asset price details from the state.
        * This mutation removes the price details at the specified index from the selectedAsset's priceDetails array.
        * @param {AssetsState} state - The state object of AssetModule.
        * @param {Object} payload - The payload containing the index of the price details to remove.
        */
        removeAssetPriceDetails(state, payload) {
            const { index } = payload
            state.selectedAsset?.feature?.splice(index, 1);
        },

        /**
        * Updates the selected asset's general information.
        * @param {Object} state - The current state of the module.
        * @param {Object} payload - The new general information.
        */
        setProposalItemInfo(state, payload) {
            const { initItem, item } = payload;
            (state.selectedAsset as any)[item] = initItem;
        },
        /**
         * Updates the selected list of files.
         * @param {Object} state - The current state of the module.
         * @param {Array} payload - The new list of selected files.
         */
        setFiles(state, payload) {
            if (state.selectedAsset) {
             state.selectedAsset.images= payload;
            }
               

        },
        setItemFiles(state, payload) {
            const { selectedFiles, item } = payload;
            (state.selectedAsset as any)[item].selectedFiles = selectedFiles;
        },
        setGoodResidualValue(state, payload) {
            if (state.selectedAsset?.residualValue) {
                state.selectedAsset.residualValue = payload;
            }
        },
        setPackResourceUID(state, payload) {
            if (state.selectedAsset) {
                state.selectedAsset.packid = payload;
            }
        },
        setGoodFeesServices(state, payload) {
            if (state.selectedAsset)
                state.selectedAsset.FeesItems = payload;
        },
        /**
         * Mutation to update the list of selected goods catalogs in the state.
         * It empties the "goodCatalogsSearch" array, if it exists, and then populates it with new data from the payload.
         * @param {Object} state - The Vuex state object.
         * @param {Array | any} payload - The new list of items to set in the "goodCatalogsSearch" array.
         */
        setGoodCatalogs(state, payload) {
            if (state.selectedAsset) {
                if (state.selectedAsset.goodCatalogs?.goodCatalogsSearch) {
                    // Clears the goodCatalogsSearch array
                    state.selectedAsset?.goodCatalogs?.goodCatalogsSearch?.splice(0, state.selectedAsset.goodCatalogs.goodCatalogsSearch.length);
                }
                // Maps the new payload and Adds all items to the goodCatalogsSearch array
                payload?.map((item: any) => state.selectedAsset?.goodCatalogs?.goodCatalogsSearch?.push(item));
            }

        },
        /**
         * Mutation to remove the selected goods catalogs.
         * This mutation clears the 'goodCatalogsSearch' array within the selected asset's 'goodCatalogs'.
         * @param {Object} state - The Vuex state object.
         */
        removeGoodCatalogs(state) {
            if (state.selectedAsset) {
                if (state.selectedAsset.goodCatalogs?.goodCatalogsSearch) {
                    // Clears the goodCatalogsSearch array
                    state.selectedAsset?.goodCatalogs?.goodCatalogsSearch?.splice(0, state.selectedAsset.goodCatalogs.goodCatalogsSearch.length);
                }
            }
        },
        setGoodSelected(state, payload) {
            state.selectedAsset = payload
        },
        SET_SELECTED_ITEMS(state, newSelected) {
            state.selectedItems = newSelected;

        },
        /**
         * Updates the selected assets in the store.
         *
         * @param {object} state - The Vuex store state.
         * @param {Array<AssetSearch>} selectedGoods - The list of selected assets.
         */
        updateSelectedGoods(state, selectedGoods) {
            if (state.selectedAsset) {
                state.selectedAsset.selectedGoods = selectedGoods
            }
        },
        /**
         * Updates the saved assets in the store.
         *
         * @param {object} state - The Vuex store state.
         * @param {Array<AssetSearch>} tempSelectedGoods - The list of saved assets.
         */
        updateTempSelectedGoods(state, tempSelectedGoods) {
            state.tempSelectedGoods = tempSelectedGoods;
        },
        /**
         * Updates the selected catalogs in the store.
         *
         * @param {object} state - The Vuex store state.
         * @param {Array<GoodCatalogsSearch>} selectedCatalogs - The list of selected catalogs.
         */
        updateSelectedCatalogs(state, selectedCatalogs) {
            if (state.selectedAsset) {
                state.selectedAsset.selectedCatalogs = selectedCatalogs
            }
        },
        /**
         * Updates the saved catalogs in the store.
         *
         * @param {object} state - The Vuex store state.
         * @param {Array<GoodCatalogsSearch>} savedCatalogs - The list of saved catalogs.
         */
        updateTempSelectedCatalogs(state, tempSelectedCatalogs) {
            state.tempSelectedCatalogs = tempSelectedCatalogs;
        },
        /**
        * Change the value of ValidField
        *
        * @param {Object} state - The current state of ValidField.
        * @param {Object} payload - The new value of ValidField.
        */
        changeValidField(state, payload) {
            state.validField = payload
        },
        deleteDocument(state, index) {
            state.selectedAsset?.documents?.splice(index, 1);
        }
    }
}
export default assetModule;
