
import {Options, Vue} from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import {formatWithMask} from "@/utils/configuration/formatters-config";
import {OrderItem, OrderProposalAssetItemType} from "@/types";
import store from "@/store";
import i18n from "@/i18n";
import { initOrders } from "@/store/services/order/orderMapper";

@Options({
  methods: { formatWithMask },
  components: { LabelAndValue },
  props: [],
})
export default class OrderSummaryAsset extends Vue {

  option = OrderProposalAssetItemType.option;
  other = OrderProposalAssetItemType.others;
  accessory = OrderProposalAssetItemType.accessory;

  get orderItems() {
   
       return store.state.summaryPanelModule.order?.orderItems || []
  }

  orderLabel(ordemItem: OrderItem) {
    return ordemItem.reference
  }

  getTotalAllAssets(assets: any) {
    let totalAssets = 0;
    for (let i = 0; i <= assets.length - 1; i++) {
      totalAssets += this.totalPriceWithTax(assets, i);
    }
    return totalAssets;
  }

  assetItem(assets: any, index: number) {
    return assets[index];
  }

  totalPriceWithTax(assets: any, index: number) {
    const totalAmountWTax = this.assetItem(assets, index)
        ? this.assetItem(assets, index).totalAmountWTax?.amount || 0
        : 0;
    return this.proposalAssetItemsTotalWithTax(assets, index) + totalAmountWTax || 0;
  }

  proposalAssetItems(assets: any, index: number) {
    return this.assetItem(assets, index) ? assets[index].orderAssetComponents || [] : [];
  }
  proposalAssetItemsTotalWithTax(assets: any, index: number) {
    return this.proposalAssetItems(assets, index)
        .filter((item: any) => item)
        .reduce((a: number, b: any) => {
          return a + (b.totalAmountWTax?.amount || 0);
        }, 0);
  }

  proposalAssetOptionItems(assets: any, index: number) {
    return (this.assetItem(assets, index).orderAssetComponents || []).filter(
        (item: any) => item.type.resourceUid === this.option
    );
  }

  proposalAssetOptionItemsTotalWithTax(assets: any, index: number) {
    return this.proposalAssetOptionItems(assets, index)
        .filter((item: any) => item)
        .reduce((a: number, b: any) => {
          return a + (b.totalAmountWTax?.amount || 0);
        }, 0);
  }

  proposalAssetAccessoryItems(assets: any, index: number) {
    return (this.assetItem(assets, index).orderAssetComponents || []).filter(
        (item: any) => item.type.resourceUid === this.accessory
    );
  }
  proposalAssetAccessoryItemsTotalWithTax(assets: any,index: number) {
    return this.proposalAssetAccessoryItems(assets, index)
        .filter((item: any) => item)
        .reduce((a: number, b: any) => {
          return a + (b.totalAmountWTax?.amount || 0);
        }, 0);
  }
  proposalAssetOtherItems(assets: any, index: number) {
    return (this.assetItem(assets, index).orderAssetComponents || []).filter(
        (item: any) => item.type.resourceUid === this.other
    );
  }

  proposalAssetOtherItemsTotalWithTax(assets: any, index: number) {
    return this.proposalAssetOtherItems(assets, index)
        .filter((item: any) => item)
        .reduce((a: number, b: any) => {
          return a + (b.totalAmountWTax?.amount || 0);
        }, 0);
  }

  getselectedAssetFrom(item:any) {
      const value = item.assetInventory
      if (value){
        return i18n.global.t("order.asset.selectAssetFrom.inventory")
      }
      else {
        return i18n.global.t("order.asset.selectAssetFrom.catalog")
    }
  }
  getBrand(brand: string) {
    return brand ? brand.replace(/asset.good.vehicle.brand./g, "") : "";
  }
  getCategory(item:any){
    return item.proposalAssetCategories && item.proposalAssetCategories[0]&& item.proposalAssetCategories[0].category&& item.proposalAssetCategories[0].category.value ? i18n.global.t("demand.asset.categoryItems."+item.proposalAssetCategories[0].category.value ): "-"
  }
  getSubCategory(item:any){
    return item.proposalAssetCategories && item.proposalAssetCategories[0]&& item.proposalAssetCategories[0].categoryClass&& item.proposalAssetCategories[0].categoryClass.value ? i18n.global.t("demand.asset.subCategoryItems." + item.proposalAssetCategories[0].categoryClass.value.substring(item.proposalAssetCategories[0].categoryClass.value.lastIndexOf(".") + 1) ): "-"
  }
  getModel(item: any) {
    return item.config && item.config.formBind && item.config.formBind.range && item.config.formBind.range.label ? item.config.formBind.range.label : "-";
  }
  getSupplierName(item: any) {
    return item.config && item.config.supplierPartyName ? item.config.supplierPartyName : "-"
  }
  getSupplierAddress(item: any) {
    return item.assetDelivery && item.assetDelivery.description? item.assetDelivery.description : "-"
  }
  getbodywork(item:any){
    return item.carBodyStyle? i18n.global.t("scales.picklist.category_class."+item.carBodyStyle.replaceAll('.','_') ) :' - '
  }
  getColor(item:any){
    return item.color? i18n.global.t("demand.asset.color.colors."+item.color) :' - '
  }
  
  async created (){
    const orderItems =await initOrders(this.orderItems)
    this.orderItems[0].orderAssets = 
      orderItems && orderItems[0] && orderItems[0].orderAssets
      ? orderItems[0].orderAssets 
      : null
     store.dispatch("summaryPanelModule/calculateOrderProposalAsset", {
      proposalAsset : this.orderItems && this.orderItems[0] && this.orderItems[0].orderAssets && this.orderItems[0].orderAssets[0] ? this.orderItems[0].orderAssets[0] : undefined
    });
  }

  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    // "color": "black"
  };

  valueStyleTotal = {
    "font-weight": "bold",
    color: "#1976D2",
  };

  divValueStyle = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "0.5rem",
  };

  divValueItem = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "0.5rem",
  };

  divValueItems = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "0.5rem",
  };

  labelStyleTotal = {
    "font-weight": "bold",
    "font-size": "1rem",
    color: "#1976D2",
  };

  labelStyleAsset = {
    "font-weight": "bold",
    "font-size": "1rem",
    color: "black",
  };

  labelStyleItems = {
    "font-weight": "bold",
    "font-size": "1rem",
    color: "gray",
  };
}
