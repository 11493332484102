
import { Vue } from "vue-class-component";
import { fetchGuestToken } from "@/store/services/dashboard";
import Notify from 'quasar/src/plugins/Notify.js';;
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { supersetConfiguration } from "@/utils/configuration/superset-config";
import {settings} from "@/settings";
import store from "@/store";

export default class Home extends Vue {
  name = "Home-Teamwill";

get daaqUser(){
return store.state.authModule?.user 
        && store.state.authModule?.user.daaqList 
        && store.state.authModule?.user.daaqList[0] 
        && store.state.authModule?.user.daaqList[0].config
        && store.state.authModule?.user.daaqList[0].config.createDAAQ
        ? store.state.authModule?.user.daaqList[0].config.createDAAQ
        : ""
}
  created() {
     
    //const daaqUser = store.state.authModule?.user.daaqList[0].config.createDAAQ;
      let tet = {
                clause: `_daaq = '${this.daaqUser}'` // Template literals make this cleaner
              };
      (supersetConfiguration.guestConfig.rls as any )=[tet]
    
    setTimeout(async () => {
      try {
        const element = document.getElementById("superset-dashboard");
        if (element) {
          await embedDashboard({
            id: supersetConfiguration.guestConfig.resources[0].id,
            supersetDomain: settings.supersetBasePath, //Use the base path from /settings/intex.ts file
            mountPoint: element,
            fetchGuestToken: () => fetchGuestToken(),
            dashboardUiConfig: supersetConfiguration.dashboardUiConfig,
          });
        }
      } catch (e) {
        console.error(e);
        Notify.create({
          message: "Error initializing the dashboard",
          color: "negative",
        });
      }
    }, 100);
  }
}
