
import i18n from "@/i18n";
import store from "@/store";
import { recipient, Third } from "@/types";
import { convertToUON, deepClone, Masks } from "@/utils";
import moment from "moment";
import { ref } from "vue";
import {Options, Vue} from "vue-class-component";
import {
  clientAppMsg,
  messageTemplate,
  objectType_odmTransferReplyMsg,
  typeMsg,
} from "@/types";
import { getOrderParties } from "@/store/services/order/orderService";
const systemUid = `odm-contactmechanism`;


@Options({
  components: {  },
    props: [],
})
export default class MessageAlert extends Vue {
    dateMessage?: any = ref(moment().format("DD/MM/YYYY"));

  // variables
    optionsRefrence = [];
    optionsReference : any= [];
    showComDialog = false;
    importantTag = false;
    subject: any = null;
    destinations = [];
    message: any = null;
    //references:any = [];
    reference: any = null;
    recipient: any = null;
    associatedRecipient: any = null;
    isInternRecipient = true
    isExternRecipient = false
    selectedFile: any =null
    cc : any = null;
    documents: any = []
    partyDocuments: any = []
    partyDocuments2: any = []
    selectedFileName: any = null
  get show() {
    return store.state.orderModule.showAlert;
  }

  toggleShowControls() {
    return store.dispatch('orderModule/toggleShow')
  }
  get subjects() {
     return store.state.messagingModule.subjects
     ? store.state.messagingModule.subjects?.map((item: any) => {
      return {
        value: item.id,
        label: item.id ? i18n.global.t("messageAlert.subjects." + item.id) : ''
      };
    })
    : []
  }
  get recipients() {
    return store.state.messagingModule.recipients ? store.state.messagingModule.recipients : [];
  }
    optionsRecipients = this.recipients.filter((item: any) => item.resourceUid);

   /*get optionsRecipients() {
        return this.recipients.filter(
        (item: any) => item.resourceUid
      );
    }*/
   updateResultRows(res: any) {
      this.optionsRefrence = res;
    }
   isRequired(val: string | any[]) {
      return (
        (val) ||
        i18n.global.t("communication.comMessage.missingField")
      );
    }
    isComboRequired(val: string | any[]) {
    return (
      (val && val.length > 0) || i18n.global.t("communication.comMessage.missingField")
    );
   }
    /*get optionsReference() {
        return this.optionsRefrence.filter(
        (item: any) => item.orderid
      );
    }*/
    get references() {
         return  this.optionsReference ? this.optionsReference : [];
    }
    //optionsReference : any =  this.optionsRefrence.filter((item: any) => item.orderid)
    thirdName(third: Third) {
    if (third) {
      if (third.organization) {
         third.organization.contacts
        return third.organization.name;
      } else if (third.person) {
        return `${third.person.firstName} ${third.person.familyName}`;
      } else return "";
    }
   }
   get onDisable() {
    return !this.reference || !this.subject || !this.recipient || !this.message 
    //|| (this.cc  && !Masks.emailMask.test(this.cc))
  }  

   getAllEmails(thirds: any) {

  if (!thirds) return [];
   
  let emails:any  = [];
  for (const third of thirds){
    let emailContact: any  ;
    let email: any ;
   if (third.associatedParty && third.associatedParty.third && third.associatedParty.third.organization) {

     emailContact = third.associatedParty.third.organization && third.associatedParty.third.organization.contacts
                     ? third.associatedParty.third.organization.contacts?.filter((contact:any) => contact?.contactMechanism?.type?.id === 'EMAIL_CONTACT')[0]
                     : undefined

    email =  emailContact?.contactMechanism?.emailAddress || undefined
  }

  if (third.associatedParty && third.associatedParty.third && third.associatedParty.third.person) {

    emailContact = third.associatedParty.third.person && third.associatedParty.third.person.contacts
                     ? third.associatedParty.third.person.contacts?.filter((contact:any) => contact?.contactMechanism?.type?.id === 'EMAIL_CONTACT')[0]
                     : undefined
       email =  emailContact?.contactMechanism?.emailAddress || undefined
                                                

  }
    email ? emails.push(...emails,email) : null
  }
  
 
  return emails;
}
    getSelectedLabel(scope: any) {
    if (scope.opt && scope.opt.externalreference) {
      return {
        id: scope.opt && scope.opt.externalreference ? scope.opt.externalreference : scope.opt.orderItems[0] && scope.opt.orderItems[0].externalreference ? scope.opt.orderItems[0].externalreference : "",
        context: "Order",
      };
    }
    }
    getSelectedRecipientLabel(scope: any) {
    if (scope.opt.username) {
      return {
         id: scope.opt.firstName + " " + scope.opt.lastName,
        context: "User",
      };
    }
    }
    getSelectedAssociatedRecipientLabel(scope: any) {
    if (scope.opt && scope.opt.associatedParty && scope.opt.associatedParty.third) {
        const thirdName = this.thirdName(scope.opt.associatedParty.third)
      return {
         id: thirdName,
        context: "Intervenant",
      }
    };
    }
    getSelectedDocumentLabel(scope: any) {
    if (scope.opt && scope.opt.label) {
      return {
        id: scope.opt && scope.opt.label ? scope.opt.label : "",
        context: "Document",
      };
    }
    }
  get order() {
    if (this.currentRoute === "order-edit") {
      return store.state.orderModule.order || {};
    } else if (this.currentRoute === "TaskDetail") {
      return store.state.taskModule.order || {};
    } else {
      return {};
    }
  }
  get orderReference() {
     if (this.currentRoute === "order-edit") {
      return store.state.orderModule.order?.reference || '';
    } else if (this.currentRoute === "TaskDetail") {
      return store.state.taskModule.order?.reference || '';
    } else {
      return '';
    }
  }
  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }
   orderParties = store.state.orderPartyModule.orderParties ? store.state.orderPartyModule.orderParties : [];
  /*get orderParties() {
    return store.state.orderPartyModule.orderParties ? store.state.orderPartyModule.orderParties : [];
  }*/
    onSelectFile(event: Event, index: number) {
    const files = (event.target as HTMLInputElement).files as FileList;
    if (files.length > 0) {
        const file = files[0];
        this.selectedFileName = file ? file.name : i18n.global.t("communication.alertMessage.noAttachedFile");
        const creationDate = new Date().toLocaleDateString(); 
        const reader = new FileReader();
        reader.onload = () => {
            const content = (reader.result as string).split(',')[1];
            this.selectedFile = {
                    //file,
                    creationDate,
                    label: file.name,
                    originalFileName: file.name,
                    format: file.type,
                    content,
                    }
        };
        reader.readAsDataURL(file);
    }
  }
  closeDialog() {
      this.toggleShowControls()
    }
   getParameters(obj: any) {
      if(obj.resourceUid){
        return {
          Context: "ORDER",
          Entity_Reference: obj.orderItems[0] && obj.orderItems[0].externalReference ? obj.orderItems[0].externalReference : '',
          Entity_Daaq: obj.daaq,
          Entity_Status: obj.status.resourceUid,
        };
      }else{
        return {
          Context: `${obj.id ? "PARTY" : "OFFER"}`,
          Entity_Reference: obj.reference,
          Entity_Daaq: obj._daaq,
          Entity_Status: `${
              obj.statuscode
                  ? obj.statuscode
                  : obj.current_status_code
          } `,
        };
      }
    }
  async sendMessage() {
      const _recipients: recipient[] = convertToUON(
          this.recipient
      );
      const senderUid = {
            objectType: "odm.user.leaseforgeuser",
            systemUid: "odm-user",
            resourceUid: this.$store.state.authModule?.user.userId,
        }

       const subject = {
            objectType: "odm.contactmechanism.messagesubject",
            systemUid: "odm-contactmechanism",
            resourceUid: this.subject &&  this.subject.value ? this.subject.value : null
        };
      clientAppMsg.resourceUid = "68815"; 

      if (
          this.subject &&
          this.recipients &&
          this.recipients.length > 0 &&
          this.message &&
          this.$store.state.authModule?.user.userId
      ) {
        _recipients.forEach((el: any) => {
          delete el.recipient.read_daaq
        })
        let  destinations = deepClone(_recipients)
        destinations.forEach((item: any) => {
          delete item.recipient.email
        }) 
        if (this.reference) {
          //for (const ref of this.reference){
          store
              .dispatch("messagingModule/messageSend", {
                objectType: objectType_odmTransferReplyMsg,
                systemUid,
                daaq: `${store.state.authModule?.daaq}${store.state.authModule?.daaqResourceUid}/${store.state.authModule?.daaqLabel}`,
                sender: `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`,
                senderUid: senderUid,
                priority: this.importantTag,
                subject: subject,
                type: typeMsg,
                recipients:destinations,
                body: this.message,
                relatedObjects: null, 
                clientApplication: clientAppMsg,
                //parameters: this.getParameters(ref),
                parameters: this.getParameters(this.order),

                messageTemplate: messageTemplate,
              })
              .then(() => {
                this.sendMail();
                //this.closeDialog();
              });
            //  }
        } 
      }
    }
    async sendMail (){
        if (this.recipient.length){
            let emailSendList : any =[]
            let emailSendPartyReferences : any =[]
            const emailSendRecipient = this.recipient && this.recipient.map((item:any) => item.email) ? this.recipient.map((item:any) => item.email) : [];
            const emailSendAssociatedRecipient = this.getAllEmails(this.associatedRecipient)  ? this.getAllEmails(this.associatedRecipient) : []      
            const emailSendCC = this.cc ? [this.cc] : [];      
            emailSendList = [...emailSendRecipient, ...emailSendAssociatedRecipient, ...emailSendCC];
             emailSendPartyReferences= this.documents && this.documents.map((item:any) => item) ? this.documents.map((item:any) => item) : [];   

            await store.dispatch('orderModule/sendMailWithDocument',{
                emailSendList: emailSendList,
                attachment: this.selectedFile || null,
                fileReference: emailSendPartyReferences && emailSendPartyReferences.length ?  emailSendPartyReferences : []
                }
            )
        }
        
    }
    emailRules() {
            return [
            (val:any) => (!val || Masks.emailMask.test(val))  || "Invalid email"
            ];
  }
   updateResulteRows(res: any) {
      this.optionsReference = res;
    }
  filterDest(val: any, update: any) {
    if (val === '') {
      update(() => {
        this.optionsRecipients = this.recipients.filter((item: any) => item.resourceUid)
      })
      return
    }

    update(() => {
      const needle = val.toLowerCase()
     const filteredList = this.optionsRecipients.filter((dest: any) => {
      if ("firstName" in dest) {
        return (
          dest.firstName.toLowerCase().includes(needle.toLowerCase()) ||
          dest.lastName.toLowerCase().includes(needle.toLowerCase()) ||
          dest.firstName.concat(' ').concat(dest.lastName).toLowerCase().includes(needle.toLowerCase()) ||
          dest.resourceUid.toLowerCase().includes(needle.toLowerCase())
        );
      }
    });

    this.optionsRecipients = filteredList;
    })
  }
  abortDestFilter() {
    this.optionsRecipients =this.recipients.filter((item: any) => item.resourceUid);
  }
  filterAssociatedDest(val: any, update: any) {
    if (val === '') {
      update(() => {
        this.orderParties = store.state.orderPartyModule.orderParties ? store.state.orderPartyModule.orderParties : [];
      })
      return
    }
    update(() => {
      const needle = val.toLowerCase()
     let filteredList
         filteredList = this.orderParties.filter((dest: any) => {
              if (dest && dest.associatedParty && dest.associatedParty.third &&  dest.associatedParty.third.organization) {
                    if ("name" in dest.associatedParty.third.organization) {
                              return (
                                dest.associatedParty.third.organization.name.toLowerCase().includes(needle.toLowerCase())
                              );
                    }
              }
              else if (dest && dest.associatedParty && dest.associatedParty.third &&  dest.associatedParty.third.person) {
                    if ("firstName" in dest.associatedParty.third.person) {
                               return (
                                    dest.associatedParty.third.person.firstName.toLowerCase().includes(needle.toLowerCase()) ||
                                    dest.associatedParty.third.person.familyName.toLowerCase().includes(needle.toLowerCase()) ||
                                    dest.associatedParty.third.person.firstName.concat(' ').concat(dest.associatedParty.third.person.familyName).toLowerCase().includes(needle.toLowerCase())                                  );
                                }
                }
              
    });
    this.orderParties = filteredList;
    })
  }
  abortAssociatedDestFilter() {
    this.orderParties =store.state.orderPartyModule.orderParties ? store.state.orderPartyModule.orderParties : [];
  }

   filterPartyDocument(val: any, update: any) {
    if (val === '') {
      update(() => {
        this.partyDocuments = this.partyDocuments2;
      })
      return
    }
    update(() => {
      const needle = val.toLowerCase()
     const filteredList = this.partyDocuments.filter((dest: any) => {
      if ("label" in dest) {
        return (
          dest.label.toLowerCase().includes(needle.toLowerCase())
        );
      }
    });

    this.partyDocuments = filteredList;
    })
  }
  async abortPartyDocumentFilter() { 
        this.partyDocuments = this.partyDocuments2;
  }
   triggerFileInput() {
      //this.$refs.fileInput.click();
      const fileInput = this.$refs.fileInput as HTMLInputElement;  // Assertion de type
      fileInput.click(); // Ouvre la boîte de dialogue de sélection de fichier
    }

  async created(){
    if (this.currentRoute === "TaskDetail"){
       const orderParties = await getOrderParties(this.order?.orderParties)
       store.dispatch('orderPartyModule/setOrderParties', { orderParties: orderParties})
    }
      store.dispatch("messagingModule/fetchSubjects")
      store.dispatch("messagingModule/fetchRecipients")
      await store.dispatch("picklistModule/partyReceivedDocuments", {
      orderReference: this.orderReference,
      callback: (res: any) => {
        this.partyDocuments = res;
        this.partyDocuments2 =res
      }
      });
       
       this.optionsRefrence  = await new Promise((resolve) => {
        store.dispatch("demandModule/searchOfferPartyOrder", {
            callback: (res:any) => {
                resolve(res);
            },
           offerFilter: {},
           filterParty: {},
           orderFilter: {},
      });
    });
      this.optionsReference  =  this.optionsRefrence.filter((item: any) => item.orderid)
      this.reference = this.order && this.order.orderItems[0] && this.order.orderItems[0].externalReference 
                       ? this.order.orderItems[0].externalReference 
                       : ""
      /*this.reference = this.references && this.references.filter((item:any)=> item.referenceorder === this.order.reference)
                                        ? this.references.filter((item:any)=> item.referenceorder === this.order.reference)                     
                                        : [this.order]*/
  }
 
}
