import {Module} from "vuex";
import {RootState, StartOrderState} from "@/types";
import {formatDate} from "@/utils/configuration/formatters-config";

const startOrderModule: Module<StartOrderState, RootState> = {
    namespaced: true,
    state: {
        bind: {
            decisionDate: formatDate(new Date(), "/"),
            comment: '',
            decision: '',
            externalReference: ''
        }
    },
    actions: {
        cleanState({ commit }) {
            commit("setInitial");
        },
        setExternalReference({ commit }, payload) {
            commit("setExternalReference",payload);
        },
    },
    mutations: {
        setInitial(state) {
            state.bind.decisionDate = formatDate(new Date(), "/")
            state.bind.comment = ''
            state.bind.decision = ''
            state.bind.externalReference = ''
        },
        setExternalReference(state, payload)  { 
            state.bind.externalReference = payload
        }
    },
};

export default startOrderModule;
