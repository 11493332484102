import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00b65955"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-document-list" }
const _hoisted_2 = { class: "row mb-2" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row q-ml-md q-mt-md scrollable-content" }
const _hoisted_5 = { class: "row tw-document-actions" }
const _hoisted_6 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_PrintDocumentListItem = _resolveComponent("PrintDocumentListItem")!
  const _component_PrintDocumentActions = _resolveComponent("PrintDocumentActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_checkbox, {
          label: _ctx.$t('task.printDocument.templateList.selectAll'),
          outlined: "",
          dense: "",
          onClick: _ctx.onSelectAllChange,
          modelValue: _ctx.checkAllModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkAllModel) = $event)),
          class: "bg-white"
        }, null, 8, ["label", "onClick", "modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (template, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-12 p-2 mb-2 default-border-radius border",
          key: index
        }, [
          _createVNode(_component_PrintDocumentListItem, {
            onChildBooleanChanged: _ctx.updateParentBoolean,
            noticeTemplate: template,
            index: index
          }, null, 8, ["onChildBooleanChanged", "noticeTemplate", "index"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_PrintDocumentActions)
      ])
    ])
  ]))
}