import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card tw-message-wrapper z-top" }
const _hoisted_3 = {
  class: "card-header bg-white d-flex justify-content-between align-items-center",
  style: {"height":"50px"}
}
const _hoisted_4 = { class: "card-title mb-0 d-flex align-items-center" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row form-group" }
const _hoisted_7 = { class: "col-lg-12 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_8 = { class: "d-block mb-0 small text-muted" }
const _hoisted_9 = { class: "col-lg-12 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_10 = { class: "d-block mb-0 small text-muted" }
const _hoisted_11 = { class: "mr-2 font-weight-normal" }
const _hoisted_12 = {
  key: 0,
  class: "theme-1"
}
const _hoisted_13 = { class: "d-flex align-items-center" }
const _hoisted_14 = { class: "badge badge-primary" }
const _hoisted_15 = { class: "ml-2" }
const _hoisted_16 = { class: "col-lg-12 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_17 = { class: "card-footer d-flex justify-content-end align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_combo_recipients = _resolveComponent("combo-recipients")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_ripple = _resolveDirective("ripple")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_ctx.showComDialog)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("communication.comMessage.newMessage")), 1),
              _createVNode(_component_q_toggle, {
                modelValue: _ctx.importantTag,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.importantTag) = $event)),
                color: "red",
                class: "tw-custom-toggle ml-2"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("communication.comMessage.subjectLabel")), 1),
                _createVNode(_component_q_select, {
                  outlined: "",
                  "bottom-slots": "",
                  modelValue: _ctx.subject,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.subject) = $event)),
                  options: _ctx.subjects,
                  rules: [this.isRequired],
                  dense: "",
                  "options-dense": ""
                }, {
                  append: _withCtx(() => [
                    (_ctx.subject)
                      ? (_openBlock(), _createBlock(_component_q_icon, {
                          key: 0,
                          name: "cancel",
                          size: "xs",
                          class: "cursor-pointer",
                          onClick: [
                            _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"])),
                            _cache[2] || (_cache[2] = ($event: any) => (_ctx.subject = null))
                          ]
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "rules"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("communication.comMessage.referenceLabel")), 1),
                _createVNode(_component_q_select, {
                  outlined: "",
                  "use-input": "",
                  multiple: "",
                  modelValue: _ctx.reference,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reference) = $event)),
                  options: _ctx.optionsReference,
                  onFilter: _ctx.filterRefFn,
                  onClick: _withModifiers(_ctx.abortRefFilter, ["stop","prevent"]),
                  dense: "",
                  "options-dense": "",
                  "emit-value": "",
                  "map-options": ""
                }, {
                  "selected-item": _withCtx((scope) => [
                    (_ctx.getSelectedLabel(scope) && _ctx.getSelectedLabel(scope).context && _ctx.getSelectedLabel(scope).id)
                      ? (_openBlock(), _createBlock(_component_q_chip, {
                          key: 0,
                          removable: "",
                          dense: "",
                          onRemove: ($event: any) => (scope.removeAtIndex(scope.index)),
                          tabindex: scope.tabindex,
                          color: "white",
                          "text-color": "primary",
                          class: "badge badge-primary d-flex",
                          onClick: _withModifiers(_ctx.abortRefFilter, ["stop","prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getSelectedLabel(scope) && _ctx.getSelectedLabel(scope).context ?
                      _ctx.$t(
                        "communication.comMessage.".concat(
                          _ctx.getSelectedLabel(scope).context
                        )
                      )
                      : ''), 1),
                            _createTextVNode(" - " + _toDisplayString(_ctx.getSelectedLabel(scope) && _ctx.getSelectedLabel(scope).id ? _ctx.getSelectedLabel(scope).id : ''), 1)
                          ]),
                          _: 2
                        }, 1032, ["onRemove", "tabindex", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  option: _withCtx((scope) => [
                    (_ctx.getSelectedLabel(scope) && _ctx.getSelectedLabel(scope).context && _ctx.getSelectedLabel(scope).id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                            class: "border p-2",
                            key: _ctx.getSelectedLabel(scope),
                            clickable: ""
                          }, scope.itemProps), [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.getSelectedLabel(scope) && _ctx.getSelectedLabel(scope).context ?
                          _ctx.$t(
                            "communication.comMessage.".concat(
                              _ctx.getSelectedLabel(scope).context
                            )
                          )
                          : ''), 1),
                              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.getSelectedLabel(scope) && _ctx.getSelectedLabel(scope).id ? _ctx.getSelectedLabel(scope).id : ''), 1)
                            ])
                          ], 16)), [
                            [_directive_ripple],
                            [_directive_close_popup]
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  "no-option": _withCtx(() => [
                    _createVNode(_component_q_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { class: "text-grey" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("main.noResults")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "onFilter", "onClick"])
              ]),
              _createVNode(_component_combo_recipients, {
                ref: "comboRecipients",
                recipients: _ctx.recipients,
                metadata: _ctx.reference
              }, null, 8, ["recipients", "metadata"]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_q_input, {
                  "data-testid": "textarea_testid",
                  modelValue: _ctx.message,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.message) = $event)),
                  outlined: "",
                  type: "textarea"
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_Transition, {
                appear: "",
                "enter-active-class": "animated fadeIn",
                "leave-active-class": "animated fadeOut"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_inner_loading, {
            showing: _ctx.isSending,
            label: _ctx.$t('communication.comMessage.waitSendMessageLabel'),
            "label-class": "text-blue",
            "label-style": "font-size: 1.1em"
          }, null, 8, ["showing", "label"]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_q_btn, {
              class: "cancelBtn btn bg-transparent text-body mr-2 w-auto",
              flat: "",
              label: _ctx.$t('communication.comMessage.cancelBtn'),
              onClick: _ctx.closeDialog
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_q_btn, {
              class: "sendBtn btn btn-primary w-auto",
              flat: "",
              push: "",
              label: _ctx.$t('communication.comMessage.sendBtn'),
              type: "submit",
              onClick: _ctx.sendMessage
            }, null, 8, ["label", "onClick"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}