import {Module} from "vuex";
import {ArchivingOrderState, RootState} from "@/types";
import {formatDate} from "@/utils/configuration/formatters-config";

const archivingOrderModule: Module<ArchivingOrderState, RootState> = {
    namespaced: true,
    state: {
        bind: {
            decisionDate: formatDate(new Date(), "/"),
            comment: '',
        }
    },
    actions: {
        cleanState({ commit }) {
            commit("setInitial");
        },
    },
    mutations: {
        setInitial(state) {
            state.bind.decisionDate = formatDate(new Date(), "/")
            state.bind.comment = ''
        },
    },
};

export default archivingOrderModule;
