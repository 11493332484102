import {
    deepClone,
    headers,
    operator,
    setOtherFilters,
    unmountSelectFieldsV2,
    getOperator, 
    globalConfig,
    mountSelectFields
} from "@/utils";
import {
    Picklist,
    QcHeader
  } from "@/types";
import { settings } from "@/settings";
import { api } from "@/auth/api";
import store from "@/store";

import {} from "@/utils";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment";

const odm_user = `odm-user`
const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}`


  export const getUsersListToCreateManager = async (filter: any, offset? : any , limit?: any) => {
    const operators = [];
  
    const qc_header: QcHeader = {
      qc: {
        queryId: "user-lease-forge-user-with-usergroup",
        offset: offset || 0,
        limit: limit || 100,
        parameters: {
          ...filter,
        },
      },
    };
     
    
    /*const PARTY_PARTY_ROLE_NETWORK = "party_party_role_network"
    const PARTY_PARTY_ROLE = "party_party_role"*/
  
  
    if (filter && filter.networkNode) {
      operators.push(
        operator({
          val: filter.networkNode,
          table: "userRoles",
          dbField: "network_node_id",
        }).EQUAL
      );
    }
    if (filter && filter.roleCode) {
      /*operators.push(
        operator({
          val: filter.roleCode,
          table: "userRoles",
          dbField: "role_code::text",
        }).LIKE
      );*/
      operators.push(
        getOperator(
          "cast(userRoles",
          "role_code  AS text)",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.roleCode),
          "role_code"
          )
        );
    }
    if (filter && filter.id) {
      operators.push(
        operator({
          val: filter.id,
          table: "user_lease_forge_user",
          dbField: "id",
        }).EQUAL
      );
    }
  
    setOtherFilters(operators, qc_header);
  
    const res = await api().get(settings.api_query_url, {
      headers: headers({
        qc: JSON.stringify(qc_header.qc),
      }),
    });
    
    const response = {
        systemUid: "odm-user",
        items: res.data.map((resItem: any) => {
          return {
            value: resItem.id,
            label: resItem.first_name+' '+resItem.last_name,
            config:{
              network_node_id : resItem.network_node_id
            }
          };
        }),
      };
      return response
    
  }

  export async function  createTeam(team: any) {  
    return api().post(`${baseUrl}/team/`, team)
  }

  export async function  getTeam(resourceUid: any) {  
    return api().get(`${baseUrl}/team/`+resourceUid+'/')
  }

  export async function  updateTeam(resourceUid:any,team: any) {  
    return api().put(`${baseUrl}/team/`+resourceUid+'/', team)
  }

  function handleTeamMembers(teamMembers:any){
    const members = []
        let memberUid = ''
        for(const member of  teamMembers){

            memberUid = member.member.resourceUid
            members.push(memberUid)
        }
      return members
}
  export const executeSaveTeam = async (payload: any) => {
    let team 
    let clone
    unmountSelectFieldsV2(payload, ["manager"]);
    const body =   store.getters["teamModule/getTeamEmpty"];
    body.manager.resourceUid = payload && payload.manager && payload.manager.resourceUid 
                                    ? payload.manager.resourceUid 
                                    : null
    body.description = payload && payload.description
                                    ? payload.description
                                    : ''
    if (payload.validity && payload.validity.from){
        body.validity = {
            from: moment(payload.validity.from,  'DD/MM/YYYY').format('YYYY-MM-DD'),
            until: payload.validity.until ? moment(payload.validity.until,  'DD/MM/YYYY').format('YYYY-MM-DD') : ''
        }
    }                               
                                        
    if (payload.members) {
        for(const member of payload.members){
            const emptyTeamMember =  store.getters["teamModule/getTeamMemberEmpty"]; 
            emptyTeamMember.member.resourceUid = member
            body.members.push(emptyTeamMember)
        }
    }                                

    if (payload && !payload.resourceUid) {
         team = await createTeam(body)
    }
    else if (payload && payload.resourceUid) 
    {
        body.resourceUid = payload && payload.resourceUid
                                    ? payload.resourceUid
                                    : null               
         team = await updateTeam(payload.resourceUid, body)
    }
    if (team ) {
        clone = deepClone(team)
         mountSelectFields(clone.data, ['manager'])
    }



    if (clone && clone.data.members) {
        clone.data.members = handleTeamMembers (clone.data.members)
    }
   return clone
  }

  function intiValidity(body: any) {
    body.validity = {
      from: "",
      until: ""
    }
  }

  export async function  executeGetTeam(resourceUid: any) {  
    let clone : any
    const team = await getTeam(resourceUid)
      if (team ) {
       
        clone = deepClone(team)
        const filter= {
          id: clone.data.manager.resourceUid
        }
        const selectedManager : any =  await getUsersListToCreateManager(filter)
        const manager = {
            type : {
                value: clone.data.manager.resourceUid,
                label: selectedManager &&
                       selectedManager.items && 
                       selectedManager.items[0]  && 
                       selectedManager.items[0].label
                       ? selectedManager.items[0].label 
                       : clone.data.manager.resourceUid
              }
        }   
        clone.data.manager = manager
        selectedManager &&
                       selectedManager.items && 
                       selectedManager.items[0]  && 
                       selectedManager.items[0].config && 
                       selectedManager.items[0].config.network_node_id
                       ? store.dispatch("teamModule/setNetworkNode", selectedManager.items[0].config.network_node_id)
                       : null
        if (!clone.data.validity) {
            intiValidity(clone.data)
         }
         else {
            clone.data.validity.from = clone.data.validity.from 
            ? clone.data.validity.from : ''
            clone.data.validity.until = clone.data.validity.until 
            ? clone.data.validity.until : ''

         }
    }

    if (clone && clone.data.members) {
        clone.data.members = handleTeamMembers (clone.data.members)
      return clone
    }
}
    

  export function searchTeams(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: "search-team"
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
      
      if (filter && filter.networkNode !== undefined) {
        operators.push(
          getOperator(
            "party_party_role_network",
            "network_node_id",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.networkNode),
            "network_node_id"
          )
        );
      }
      if (filter && filter.manager !== undefined) {
        operators.push(
            getOperator(
              "user_team",
              "manager_code",
              "EQUAL",
              globalConfig.formatters.getEqualValue(filter.manager),
              "manager_code"
            )
          );
      }
      if (filter && filter.description) {
        operators.push(
            getOperator(
              "lower(user_team",
              "_description)",
              "LIKE",
              globalConfig.formatters
                .getLikeValue(filter.description)
                .toLowerCase(),
              "_description"
            )
          );
      }

      if (filter?.member && filter.member !== null && filter.member.length > 0) {
        operators.push(
          getOperator(
            "cast(teamMembers",
            "membersusernames  AS text)",
            "LIKE",
            globalConfig.formatters.getLikeValue(filter.member),
            "membersusernames"
          )
        );
      }
     
      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 100;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}