import { getProcess } from './../../services/task/actions/uploadDocumentService';
import {Module} from "vuex";
import {
    InventoryStatusEnum,
    Order,
    OrderAssetComponent,
    OrderAssetDelivery,
    OrderAssociatedParty,
    OrderItem,
    OrderModeStatus,
    OrderNetwork,
    OrderNetworkNode,
    OrderParty,
    OrderPartyRoleEnum,
    OrderState,
    OrderStatusEnum,
    OrderVehicle, Task, TaskModule
} from "@/types";
import {calculateProposalAsset, getAssetProduct} from "@/store/services/asset/assetMapper";
import {deepClone, globalConfig, PreconditionAction, sleep} from "@/utils";
import store from "@/store";
import {
    getAssetCustom,
    getassetTax,
    getCommercial,
    getNetwork,
    getNetworkNode,
    getPartyUser
} from "@/store/services/offerService";
import {convertAssetDeliveryModel, convertOrderVehicleToProductVehicle, convertProductAssetToOrderAsset, getOrderAssetServices, initOrders} from "@/store/services/order/orderMapper";
import {formatDate} from "@/utils/configuration/formatters-config";
import {createOrderAlert, createProductCategoryClass, deleteOrder, deleteOrderAlert, getInventoryItem, getOrder, getOrderItem, getOrderOrchestration, searchOrder, updateDateOrderItem, updateInventoryItem, updateOrderAlert, updateOrderItem, updateOrderStatus} from "@/store/services/order/orderApi";
import {
    buildAlertList,
    buildOrderAlert,
    getAlertReasons,
    getOrderAlertList,
    calculateMargin,
    getOrderEditMode,
    getOrderParties,
    getOrderTaskHistory,
    getPlaningEvent,
    initOrderWorkflow,
    orderDuplicateMode,
    searchAssetOrder,
    getOrderStatusProgress,
    getDocumentsMove,
    getPlaningEventExport
} from "@/store/services/order/orderService";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import router from "@/router";
import { getAdditionalInformation } from '@/store/services/picklist';
import orderFinancingModule from "@/store/modules/order/financing/orderFinancingModule";
import { getProductFeature } from '@/store/services/financing/product';
import { getGoodProposalItem, selectGoodImage } from '@/store/services/configuration/AssetService';
import moment from 'moment';
import { addPartieToOrders, saveOrderOrch } from '@/store/services/order/orderOrch';
import { sendMailWithDocument } from '@/store/services/order/sendMail';


const orderModule: Module<OrderState, any> = {
    namespaced: true,
    state: {
        mode: OrderModeStatus.CREATE,
        showAlert:false,
        index: -1,
        orders: [],
        order: {},
        orderItem: {},
        preConditionDetailVisible :false,
        orderFinancingModule: {
            financing: {},
        },
        selectedAssetFrom:{},
        selectedProductAsset:{},
        tempSelectedOrderGoods: {},
        tempGoodOrderFilter: {},
        goodOrderFilter: {},
        selectedEventPlanning:{
            title: '',
            actionStartDate:'',
            actionStartHour:'',
            actionEndDate:'',
            actionEndHour:'',
            comment:'',
            clientName:'',
            range:'',
            brand:'',
            assetProduct:'',
            description:'',
            status_code:''
        },
        alertList: [],
        selectedSearchOrder: undefined,
        newParty:{},
        deliveryFilter: {},
        visibleColumns: [
            "status_code",
            "activity",
            "referenceOrder",
            "externalReference",
            "clientreference",
            "associatedpartyname",
            "networkNode",
            "sellerName",
            "ordercreateddate",
            "initialdeliverydate",
            "previsionaldeliverydate",
            "brandRangeVersion",
            "chassis_reference",
            "registrationnumber"
          ],
        previousRoute: undefined
    },
    getters: {
        getMode(state) {
            return state.mode
        },
        isModeCreate(state) {
            return state.mode === OrderModeStatus.CREATE
        },
        isModeEdit(state) {
            return state.mode === OrderModeStatus.EDIT
        },
        getVisibleColumns(state){
            return state.visibleColumns
        },
        getOderInit(): Order {
            return {
                reference: '',
                channel: '',
                previsionDeliveryDate: '',
                effectiveDeliveryDate: '',
                creationDate: '',
                assignedSalesPerson: '',

                orderParties: [store.getters["orderModule/getOrderAssociatedPartyInit"]],
                orderNetworks: store.getters["orderModule/getOrderNetworkInit"],
                orderNetworkNodes: store.getters["orderModule/getOrderNetworkNodeInit"],
                status: {
                    resourceUid: OrderStatusEnum.ORDER,
                    objectType: 'odm.order.orderstatus'
                },
                orderItems: [store.getters["orderModule/getOrderItemInit"]],
                objectType: 'odm.order.order',
                systemUid: 'odm-order',
                alertList: [],
                orderSubsidies: [],
            }
        },
        getOrderAssociatedPartyInit(): OrderAssociatedParty {
            return {
                orderParty: store.getters["orderModule/getOrderPartyInit"],
                objectType: 'odm.order.orderassociatedparty',
                systemUid: 'odm-order',
            }
        },
        getOrderNetworkInit(): OrderNetwork {
            return {
                network: {
                    objectType: 'odm.party.network',
                    systemUid: 'odm-party',
                },
                objectType: 'odm.order.ordernetwork',
                systemUid: 'odm-order',
            }
        },
        getOrderNetworkNodeInit(): OrderNetworkNode {
            return {
                networkNode: {
                    objectType: 'odm.party.networknode',
                    systemUid: 'odm-party',
                },
                objectType: 'odm.order.ordernetworknode',
                systemUid: 'odm-order',
            }
        },
        getOrderItemInit(): OrderItem {
            return {
                reference: '',
                externalReference: '',
                initialDeliveryDate: '',
                previsionDeliveryDate: '',
                effectiveDeliveryDate: '',
                orderItemParent: null,
                type: {
                    objectType: 'odm.order.orderitemtype',
                    systemUid: 'odm-order',
                    resourceUid: 'CUSTOMER'
                //     TODO verify
                },
                status: {
                    objectType: 'odm.order.orderitemstatus',
                    systemUid: 'odm-order',
                },
                orderAssets: [store.getters["orderModule/getOrderAssetInit"],store.getters["orderModule/getOrderAssetInit"]],
                objectType: 'odm.order.orderitem',
                systemUid: 'odm-order',
            }
        },
        getOrderPartyInit(): OrderParty {
            return {
                party: {
                    objectType: 'odm.party.party.organization.frenchperson',
                    systemUid: 'odm-party',
                },
                role: {
                    resourceUid: OrderPartyRoleEnum.CUSTOMER,
                    objectType: 'odm.order.orderpartyrole'
                },
                objectType: 'odm.order.orderparty',
                systemUid: 'odm-order',
            }
        },
        getAssetTradeInInit(): any {
            return {
                engagementAmount: {
                  amount: 0.0,
                  currency: 'EUR'
                },
                overEstimationAmount: {
                  amount: 0.0,
                  currency: 'EUR'
                },
                
                conversionBonus:{
                  amount: 0.0,
                  currency: 'EUR'
                },
                origin: '',
                objectType: 'odm.order.orderassettradein',
                systemUid: 'odm-order',
              }
        },

        getOrderAssetInit(): OrderVehicle {
            return {
                isFirstOwner:false,
                description: '',
                amountWoTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                bonus: {
                    amount: 0,
                    currency: 'EUR'
                },
                tradeInCommitment:{
                    amount: 0,
                    currency: 'EUR'
                },
                bbProvision: {
                    amount: 0,
                    currency: 'EUR'
                },
                discountPercentage: 0,
                discountAmount: {
                    amount: 0,
                    currency: 'EUR'
                },
                taxAmount: {
                    amount: 0,
                    currency: 'EUR'
                },
                amountWTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                quantity: 0,
                totalAmountWoTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                totalTaxAmount: {
                    amount: 0,
                    currency: 'EUR'
                },
                totalAmountWTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                purchaseAmountWoTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                purchaseAmountWTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                assetMargin: {
                    amount: 0,
                    currency: 'EUR'
                },
                assetProduct: undefined,
                packAssetProduct: undefined,
                assetTradeIn:store.getters["orderModule/getAssetTradeInInit"] ,
                supplier: store.getters["orderModule/getOrderPartyInit"],
                orderAssetComponents: [],
                orderAssetServices: [store.getters["orderModule/getOrderAssetServiceInit"]],
                assetDelivery: store.getters["orderModule/getOrderAssetDeliveryInit"],
                objectType: 'odm.order.orderasset.ordergood.ordervehicle.ordercar',
                systemUid: 'odm-order',
                flagNew: true,
                flagVD: false,
                nbOfCylinder: 0,
                nbOfGear: 0,
                taxHorsePower: 0,
                horsePower: 0,
                co2Emissions: 0,
                mineType: 0,
                fuelConsumptionUrban: 0,
                fuelConsumptionExtraUrban: 0,
                fuelConsumptionCombined: 0,
                brand: '',
                range: '',
                version: '',
                carBodyStyle: '',
                gearBox: '',
                engineType: '',
                matriculation: '',
                matriculationDate: '',
                chassisReference: '',
                constructionDate: '',
                currentMileage: 0,
                vehicleLength: '',
                color: '',
                firstCarRegistrationDate: '',
                insideColor: '',
            }
        },
       
        getOrderAssetComponentInit(): any {
            return {
                config: {
                    required: false,
                    selected: true,
                    selectModel: {
                      label: "",
                      id: "",
                    },
                    unitaryAmountModel: 0,
                    amountWithTaxModel: 0,
                    discountAmountModel: 0,
                    taxValueModel: {
                      label: "",
                      id: "",
                    },
                    purchaseAmountWTaxModel: 0,
                    purchaseAmountWoTaxModel: 0,
                    reference: undefined,
                  },
                type: {
                    objectType: 'odm.order.orderassetcomponenttype',
                    systemUid: 'odm-order',
                },
                label: '',
                amountWTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                discountPercentage: 0,
                discountAmount: {
                    amount: 0,
                    currency: 'EUR'
                },
                taxAmount: {
                    amount: 0,
                    currency: 'EUR'
                },
                amountWoTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                quantity: 1,
                totalAmountWoTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                totalTaxAmount: {
                    amount: 0,
                    currency: 'EUR'
                },
                totalAmountWTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                objectType: 'odm.order.orderassetcomponent',
                systemUid: 'odm-order',
            }
        },
        getOrderAssetServiceInit(): any {
            return {
                type: {
                    objectType: 'odm.order.orderassetservicetype',
                    systemUid: 'odm-order',
                },
                label: '',
                purchase: "false",
                duration: 0,
                mileage: 0,
                activatedContract: false,
                amountWTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                amountWoTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                commissionAmount: {
                    amount: 0,
                    currency: 'EUR'
                },
                purchaseAmountWTax:{
                    amount: 0,
                    currency: 'EUR'
                },
                objectType: 'odm.order.orderassetservice',
                systemUid: 'odm-order',
            }
        },
        getOrderAssetDeliveryInit(): OrderAssetDelivery {
            return {
                deliveryDate: '',
                initialDeliveryDate:'',
                previsionDeliveryDate:'',
                effectiveDeliveryDate:'',
                deliveryAddress: {
                    objectType: '',
                    systemUid: '',
                },
                description: '',
                objectType: 'odm.order.orderdeliverydetails',
                systemUid: 'odm-order',
            }
        },
        getSelectedAssetFromInit() {
            return {}
        },
        getSlectedProductAssetInit() {
            return {}
        },
        getOrderReference(state){
            return state.order?.reference;
        },
        getInitSelectedEventPlanning(){
         return  {
            title: '',
            actionStartDate:'',
            actionStartHour:'',
            actionEndDate:'',
            actionEndHour:'',
            comment:''
          }
        }
    },
    actions: {
        async initOrderEditMode({ commit}, payload) {
            const { resourceUid } = payload
            if (!resourceUid) {
                throw new Error('ResourceUid is mandatory!')
            }
            this.dispatch('taskModule/setModule', { module: TaskModule.ORDER })
            try {
                const order = await getOrderEditMode(resourceUid)
                commit('setOrder', order)
                store.dispatch("summaryPanelModule/setSummaryOrder", { order: order });
                const orderParties = await getOrderParties(order.orderParties)
                this.dispatch('orderPartyModule/setOrderParties', { orderParties: orderParties})
                //const {orderItems} = order
                const orderItems =await initOrders(order.orderItems)
                commit('setOrders', orderItems || [])
                const assetDeliveryModel = await convertAssetDeliveryModel(orderItems,order)
                commit('setAssetDeliveryModel', assetDeliveryModel)

                store.dispatch("commandModule/setMode", "edit");
            //     TODO EF-202 review
            //store.dispatch('printDocumentModule/toggleShowButton')
            } catch (e) {
                Notify.create({
                    message: 'ERROR: ' + e,
                    color: 'negative'
                })
            }
        },
        toggleShow({ commit }) {
            commit('toggleShow')
        },
        deleteOrder({ commit }, payload) {
            const { resourceUid } = payload
            deleteOrder(resourceUid)
                .then((res:any) => {
                    Notify.create({
                        message: i18n.global.t("order.messages.deleteSuccess"),
                        color: "positive",
                    })
                    
                })
                .catch((err:any) => {
                    console.error(err);
                    Notify.create({
                      message: err,
                      color: "negative",
                    });
                })
        },
        async orderDuplicateMode({ commit}, payload) {
            const { resourceUid ,number,length,callback} = payload
            if (!resourceUid) {
                throw new Error('ResourceUid is mandatory!')
            }
            this.dispatch('taskModule/setModule', { module: TaskModule.ORDER })
            try {
                const order = await orderDuplicateMode(resourceUid,number,length)
				if (callback) {
                    callback(order.data)
                }

            } catch (e) {
                Notify.create({
                    message: 'ERROR: ' + e,
                    color: 'negative'
                })
            }
        },
		
        initOrder({ commit,  getters }, payload) {
            const order = getters.getOderInit
            order.effectiveDeliveryDate = formatDate(new Date(), "/")
            this.dispatch('taskModule/setModule', { module: TaskModule.ORDER })
            commit('setOrder', order)
        },
        async initOrderItem({ commit, getters,state }, payload) {
            const { index, orderItem } = payload
            commit('setIndex', index)
            const isCreateMode = !orderItem
            const orderItemState: OrderItem = deepClone(isCreateMode ? getters.getOrderItemInit : orderItem)
            /*if (isCreateMode) {
                orderItemState.effectiveDeliveryDate = formatDate(new Date(), "/")
            }*/
            commit('setMode', isCreateMode ? OrderModeStatus.CREATE : OrderModeStatus.EDIT)
            if (!orderItemState || !orderItemState.orderAssets?.length || !(payload.index > -1)) {
                throw new Error('Invalid state initialization')
            }
            const orderAssets =  orderItemState.orderAssets[0]
            const asset= await convertOrderVehicleToProductVehicle(orderAssets)
            orderItemState.orderAssets[0].config=asset.config
            orderItemState.orderAssets[0].proposalAssetItems=asset.proposalAssetItems
            commit('setOrderItem', orderItemState)
            commit('setAsset', asset)
            const newOrders: any = deepClone(state.orders)

            newOrders.push(orderItemState)
            commit('setOrders', newOrders)
        },
        async initOrderWorkflow({ state}, payload) {
            const { order } = state
            const { callback } = payload || {}
            try {
                await initOrderWorkflow(order)
                this.dispatch('taskModule/getFutureTaskByReferenceAndTaskDefinition', {
                    orderReference: order.reference,
                    taskDefinition: undefined,
                    onSuccess: async (task: Task) => {
                        await this.dispatch('taskModule/setModule', { module: TaskModule.ORDER })
                        await this.dispatch('releaseLockState')
                        await this.dispatch('taskModule/claimOwnership', { task, callback,blockRedirection: false })
                        
                    },
                    onFail: async () => {
                        await this.dispatch('releaseLockState')
                        await this.dispatch('taskModule/getTasks')
                        if (callback) callback()
                        await router.push("/task/list");
                    }
                })
            } catch (e: any) {
                Notify.create({
                    message:  e.message || e,
                    color: "negative",
                })
            }
        },
         saveOrder({ state, dispatch }, payload) {
            const { order, orders } = state
            const {orderParties} =payload
            order.orderItems = orders
            saveOrderOrch(order,orderParties).then((res: any) => {
                if (payload && payload.callback) {
                    payload.callback(res)
                }

                if(res?.resourceUid){
                    Notify.create({
                        message: i18n.global.t("order.messages.createSuccess"),
                        color: "positive",
                    })
                    dispatch('initOrderEditMode', {
                        resourceUid: res.resourceUid
                    })
                }/*else{
                    Notify.create({
                        message: 'ERROR: ' + res,
                        color: 'negative'
                    });
                }*/

            })
            
        },
        calculateMargin({ state }, payload) {
            const { order, orders } = state         
            order.orderItems = orders
            calculateMargin(state.order)
                .then((res: any) => {
                    if (payload && payload.callback) {
                        payload.callback(res)
                    }
                })
        },
        async fetchUser() {
            return getPartyUser();
        },
        network({ commit }, payload) {
            const { filter, callback, orderNetworkNodes } = payload
            getNetwork(filter)
                .then(res => {
                    if (res.items.length == 1 && orderNetworkNodes === "") {
                        commit('setNetwork', res.items[0]);
                        callback(res);
                    } 
                })
                .catch(e => console.error(e))
        },
        searchAssetOrder({ commit }, payload) {
            const { filter, callback } = payload
            searchAssetOrder(filter)
                .then(res => {
                    
                    callback(res);
                })
                .catch(e => console.error(e))
        },
        async getPlaningEvent({ commit }, payload) {
            const { callback,filter } = payload
            const res = await getPlaningEvent(filter)

            commit('setPlaningEvent', res);
            callback(res);
     
        },
        async getPlaningEventExport({ commit }, payload) {
            const { callback,filter } = payload
            const res = await getPlaningEventExport(filter)
          
            callback(res);
     
        },
        setPlaningEvent({ commit }, payload) {
            commit('setPlaningEvent', payload);
        },
        selectedDayEvent({ commit }, payload) {
          
            commit('selectedDayEvent', payload);     
        },
        changeDateEvent({ commit }, payload) {
          
            commit('changeDateEvent', payload);     
        },
       
        initSelectedEventPlanning({ commit,  getters }) {
            const selectedEventPlanning = getters.getInitSelectedEventPlanning
            commit('setSelectedEventPlanning', selectedEventPlanning)
        },
        setSelectedEventPlanning({ commit},  payload ) {
             commit('setSelectedEventPlanning', payload)
        },
        commercial({ commit }, payload) {
            const { filter, callback } = payload
            getCommercial(filter)
                .then(res => {
                    if (res.items.length == 1) {
                        commit('setCommercial', res.items[0]);
                    }
                    callback(res);
                })
                .catch(e => console.error(e))
        },

        networkNode({ commit }, payload) {
            const { filter, callback } = payload
            getNetworkNode(filter)
                .then(res => {
                    if (res.items.length == 1) {
                        commit('setNetworkNode', res.items[0]);
                    }
                    callback(res);
                })
                .catch(e => console.error(e))
        },

        setOrderAssociatedParty({ commit }, payload) {
            commit('setOrderAssociatedParty', payload)
        },
        setChannel({ commit }, payload) {
            commit('setChannel', payload)
        },
        setSupplier({ commit }, payload) {
            commit('setSupplier', payload)
        },
       async createOrderAlert({ state, commit }, payload) {
        const { orderId,comment, reasons } = payload
           const orderAlert = await  buildOrderAlert(comment,reasons)
             if (orderAlert){
                   const  createdAled=await createOrderAlert(orderAlert,orderId)
                   console.log(createdAled)
                   await sleep(250)
                    const filter={
                        orderId : orderId
                        }
                    const list= await getOrderAlertList(filter)
                    const alertList=  buildAlertList(list)
                    Notify.create({
                                message: "Order Alert created...",
                                color: "positive",
                            })

                    commit('setOrderAlertList',alertList)
             }
          },
          updateOrderAlert({ state, commit }, payload) {
            const { alertId, alert} = payload
                    updateOrderAlert(alert,alertId)
                    .then(res => {
                        Notify.create({
                            message: "Order Alert updated...",
                            color: "positive",
                        })
                    })
                    .catch(e => console.error(e))
              },
              async orderAlertDelete({ state, commit }, payload) {
                const { alertId} = payload
                await  deleteOrderAlert(alertId)
                  },
         async deleteOrderAlert({ state, commit }, payload) {
                const {orderId ,alertId} = payload
                      const item = await  deleteOrderAlert(alertId)
                      
                      await sleep(250)
                            const filter={
                                orderId:orderId
                                }
                          const list= await getOrderAlertList(filter)
                    
                           const alertList=  buildAlertList(list)
                           
                            Notify.create({
                                message: "Order Alert deleted...",
                                color: "positive",
                            })

                            commit('setOrderAlertList',alertList)
               
            },
          setorderSubvention({ state, commit }, payload) {
            if (state.order?.orderSubsidies) {
              const oldorderSubventiont = state.order.orderSubsidies;
              oldorderSubventiont.push(payload);
              commit("setorderSubvention", oldorderSubventiont);
            } else {
              commit("setorderSubvention", [payload]);
            }
          },
        async insertOrderItem({ state, commit }) {
            const { orderItem, asset,order } = state
            const orderAssetServices = getOrderAssetServices(orderItem.orderAssets[0].orderAssetServices)
            orderItem.orderAssets[0] = convertProductAssetToOrderAsset(asset,orderItem)
            orderItem.orderAssets[0].orderAssetServices=orderAssetServices
            store.state.orderModule.tempSelectedOrderGoods && store.state.orderModule.tempSelectedOrderGoods.inventory_item_code 
            ? orderItem.orderAssets[0].inventoryItemCode= store.state.orderModule.tempSelectedOrderGoods.inventory_item_code 
            : null
            const newOrders: any = deepClone(state.orders)
            newOrders.push(orderItem)
            commit('setOrders', newOrders)

            const assetDeliveryModel = await convertAssetDeliveryModel(newOrders,order)
            commit('setAssetDeliveryModel', assetDeliveryModel)
        },
        async editOrderItem({ state, commit }) {
            const { index, orderItem, asset,order } = state
            const newOrders: any = deepClone(state.orders)
            const orderAssetServices = getOrderAssetServices(orderItem.orderAssets[0].orderAssetServices)
            orderItem.orderAssets[0] = convertProductAssetToOrderAsset(asset,orderItem)
            orderItem.orderAssets[0].orderAssetServices=orderAssetServices
            newOrders[index] = orderItem
            commit('setOrders', newOrders)

            const assetDeliveryModel = await convertAssetDeliveryModel(newOrders,order)
            commit('setAssetDeliveryModel', assetDeliveryModel)
        },
        deleteOrderItem({ state, commit }, payload) {
            const {index, inventoryItemCode} = payload
            const orderToRemove = state.orders[index]
            if (inventoryItemCode){
                this.dispatch("orderModule/updateInventoryItem",{
                    inventoryItemCode: inventoryItemCode,
                    status: InventoryStatusEnum.IN_STOCK
                })
            }
            const newOrders: any = deepClone(state.orders.filter((order: any) => order !== orderToRemove))
            commit('setOrders', newOrders)
        },
        searchAsset({ commit, state }, payload) {
            const { id, category, refreshAssetItems,index,productName} = payload
            const { orders,order } = state
            const proposalAsset = state.orders[index].orderAssets[0]
            const assetDeliveryModel = deepClone(proposalAsset.config.assetDeliveryModel)
            const supplier = deepClone(proposalAsset.config.formBind.supplier)
            if (id && proposalAsset) {
                getAssetProduct(id, proposalAsset, category, refreshAssetItems).then(async res => {
                    res.config.assetDeliveryModel = assetDeliveryModel
                    res.config.formBind.supplier = supplier
                    res.config.supplierPartyName= supplier.name
                    commit("setAsset", res)
                    const orderAssetServices = getOrderAssetServices(orders[index].orderAssets[0].orderAssetServices)
                    orders[index].orderAssets[0] = convertProductAssetToOrderAsset(res,orders[index])
                    orders[index].orderAssets[0].orderAssetServices=orderAssetServices
                    orders[index].orderAssets[0].config.assetDeliveryModel = assetDeliveryModel
                    orders[index].orderAssets[0].config.formBind.supplier = supplier
                    orders[index].orderAssets[0].config.supplierPartyName= supplier.name
                    orders[index].orderAssets[0].config.proposalAssetCalculated.product.assetMargin = res.amountWoTax?.amount -( res.purchaseAmountWoTax?.amount || 0) || 0;
                    orders[index].orderAssets[0].config.proposalAssetCalculated.product.discountAmount = 0;
                    orders[index].orderAssets[0].config.proposalAssetCalculated.product.discount = 0;
                    orders[index].orderAssets[0].config.proposalAssetCalculated.product.purchaseAmountWoTax = res.purchaseAmountWoTax?.amount || 0;
                    orders[index].orderAssets[0].config.proposalAssetCalculated.product.purchaseAmountWTax = res.purchaseAmountWTax?.amount || 0;
                    orders[index].orderAssets[0].bonus={amount: 0, currency: 'EUR'};
                    orders[index].orderAssets[0].bbProvision={amount: 0, currency: 'EUR'};
                    orders[index].orderAssets[0].tradeInCommitment={amount: 0, currency: 'EUR'};
                    
                    if(productName){                 
                    orders[index].orderAssets[0].description=productName
                    }
                    store.state.orderModule.tempSelectedOrderGoods && store.state.orderModule.tempSelectedOrderGoods.inventory_item_code 
                    ? orders[index].orderAssets[0].inventoryItemCode= store.state.orderModule.tempSelectedOrderGoods.inventory_item_code 
                    : null
                    commit('setOrders', orders)

                    const assetDeliveryModelNew = await convertAssetDeliveryModel(orders,order)
                    commit('setAssetDeliveryModel', assetDeliveryModelNew)
                    console.log("newOrders")
                    if (payload.callback) {
                        payload.callback(res)
                    }
                }).catch((e: any) => {
                    console.error(e)
                })   
            }
        },
        getAdditionalInformation({ commit }, payload) {
            const { filter, callback } = payload
            getAdditionalInformation(filter)
                .then(res => {
                    callback(res);
                })
                .catch(e => console.error(e))
        },
        addNewProposalAssetItem({state, commit }, payload) {
            const { type, index } = payload
            const proposalAsset = state.orders[index].orderAssets[0]
            const orderAssetComponent = deepClone(store.getters["orderModule/getOrderAssetComponentInit"])
            orderAssetComponent.config.reference = (Math.random() + 1).toString(36).substring(7)
            orderAssetComponent.type = type
            proposalAsset.orderAssetComponents.push(orderAssetComponent)
            console.log('addNewProposalAssetItem')
            commit('setProposalAssetByIndex', { proposalAsset, index })
            commit('setOrders', state.orders)
        },
        updateProposalAssetItem({ commit }, payload) {
            commit('updateProposalAssetItem', payload)
        },
        removeProposalAssetItem({ commit }, payload) {
            commit('removeProposalAssetItem', payload)
        },
        calculateOrderProposalAsset({ commit }, payload) {
            const { index } = payload;
            const proposalAsset =
              this.state.orderModule.orders[index].orderAssets[0];
            if(proposalAsset){
              calculateProposalAsset(proposalAsset);
              commit("setProposalAssetByIndex", { proposalAsset, index });
            }
          },
        calculateProposalAsset({ state, commit }) {
            const proposalAsset = state.orders[0].orderAssets[0]
            calculateProposalAsset(proposalAsset)
            commit('setProposalAssetByIndex', { proposalAsset })
        },
        setProposalAssetByIndex({  state , commit }) {
            const proposalAsset = state.asset
            commit('setProposalAssetByIndex', { proposalAsset })
        },
        calculateProposalAssetItem({ state, commit }, payload) {
            const { index, proposalAssetItem } = payload
            const proposalAsset = state.orders[index].orderAssets[0]
            calculateProposalAsset(proposalAsset)
            commit('updateProposalAssetItem', { proposalAssetItem, index })
        },
        assetCustom({ commit }, payload) {
            const { filter, callback } = payload
            getAssetCustom(filter)
                .then(res => {
                    callback(res.items);
                })
                .catch(e => console.error(e))
        },
        
        assetTax({ commit }, payload) {
            const { callback } = payload
            getassetTax()
                .then(res => {
                    callback(res.items);
                })
                .catch(e => console.error(e))
        },
        setOrderItemStatus({ commit }, payload) {
            commit('setOrderItemStatus', payload)
        },
        setPreConditionValid({ commit }, payload) {
            commit('setPreConditionValid', payload)
        },
        setOrderStatus({ commit }, payload) {
            commit('setOrderStatus', payload)
        },
        cleanState({ state, commit }) {
            commit('setInitial')
            this.dispatch('demandModule/cleanState')
            this.dispatch('printDocumentModule/cleanState')
            this.dispatch('orderPartyModule/cleanState')
            if(state.order.orderFinancingModule){
                this.dispatch('orderFinancingModule/cleanState')
            }
        },
        searchOrder({ commit }, payload) {
            const { filter,offset,limit, callback } = payload
            searchOrder(filter,offset,limit)
                .then(response => callback(response))
                .catch(e => console.error(e))
        },
        searchOrderDocumentsMove({ commit }, payload) {
            const { filter, callback } = payload
            getDocumentsMove(filter)
                .then(response => callback(response))
                .catch(e => console.error(e))
        },
        changeAssetReadOnly({ commit }, payload) {
            commit('setAssetReadOnly', payload)
        },
        getOrderGatheringInfo({state, commit }, payload) {
            const { callback } = state.order.resourceUid
            getProcess(state.order.resourceUid)
                .then(response => callback(response))
                .catch(e => console.error(e))
            commit('setOrderGatheringInfo', payload)
        },
        changePreconditionReadOnly({ commit }, payload) {
            commit('changePreconditionReadOnly', payload)
        },
        getOrderHistory({ commit }) {
            getOrderTaskHistory()
              .then((response) => {
                commit("setHistoriesStep", response);
              })
              .catch((err) => {
                console.error(err);
                Notify.create({
                  message: err,
                  color: "negative",
                });
            });
        },
        setOrderVariablesVisible({commit}, payload) {
            commit('setOrderVariablesVisible', payload);
        },

        setOrderDetailPreconditionVisible({commit}, payload) {
            commit('setOrderDetailPreconditionVisible', payload);
        },
        setOrderDetailPrecondition({commit}, payload) {
            commit('setOrderDetailPrecondition', payload);
        },
        setOrderStatusHistoryVariablesSelected({commit}, payload) {
            commit('setOrderStatusHistoryVariablesSelected', payload);
        },
        initSelectedAssetFrom({ commit,  getters }) {
            const selectedAssetFrom = getters.getSelectedAssetFromInit
            commit('setSelectedAssetFrom', selectedAssetFrom)
        },
        initSelectedProductAsset({ commit,  getters }) {
            const slectedProductAsset = getters.getSlectedProductAssetInit
            commit('setSlectedProductAsset', slectedProductAsset)
        },
        updateTempSelectedOrderGoods({ commit }, tempSelectedOrderGoods) {
            commit('updateTempSelectedOrderGoods', tempSelectedOrderGoods);
        },
        SetSelectedSearchOrder({ commit }, payload) {
            commit('SetSelectedSearchOrder', payload);
        },
        SetSelectedSearchOrderItem({ commit }, payload) {
            commit('SetSelectedSearchOrderItem', payload);
        },
        setSlectedProductAsset({ commit }, payload) {
            commit('setSlectedProductAsset', payload);
        },
        updateInventoryItem({ commit }, payload) {
            const { inventoryItemCode, status } = payload;
               getInventoryItem(inventoryItemCode)
                .then( res => {
                      updateInventoryItem(res.data, status, inventoryItemCode)
                        .then(res => {
                            console.log("res ", res.data);
                        })
                        .catch(e => {
                            console.error(e);
                        });
                })
                .catch(e => {
                    console.error(e);
                });
        },
         getProduct({ commit }, payload) {
            const { productCode, callback} = payload
             getProductFeature(productCode)
            .then((res: any) => {
                const selectedProduct ={
                    id: res.resourceUid,
                    label: res.resourceUid,
                    config: {
                        resourceUid: res.resourceUid,
                        productUid: {
                          objectType: res.objectType,
                          systemUid: res.systemUid,
                          resourceUid: res.resourceUid
                        },
                        productName: res.name,
                        productReference: res.reference,
                        productStatus: res.status,
                        classifications: res.classifications
                      } 
                    }
                callback(selectedProduct);
            })
            .catch(e => {
                console.error(e);
            });
               
            },
        gatheringProcessOrder: ({ commit }, payload) => {
            const { request } = payload;
            if(request.gatheringProcess ){
                request.gatheringProcess.sort((a:any, b: any) => {
                    return PreconditionAction.indexOf(a.assessmentContext) - PreconditionAction.indexOf(b.assessmentContext);
                });
            }
            commit("setGatheringProcessOrder", request.gatheringProcess);
        },
        updateAssetDelivery({ state, commit }, payload) {
            const { value,field,index } = payload;
            if(field==='initialDeliveryDate'){
                state.orders[index].orderAssets[0].assetDelivery.initialDeliveryDate=value
                state.orders[index].initialDeliveryDate=value
                commit('setOrders', state.orders)
            }
            if(field==='effectiveDeliveryDate'){
                state.orders[index].orderAssets[0].assetDelivery.effectiveDeliveryDate=value
                state.orders[index].effectiveDeliveryDate=value
                commit('setOrders', state.orders)
            }
            if(field==='previsionDeliveryDate'){
                state.orders[index].orderAssets[0].assetDelivery.previsionDeliveryDate=value
                state.orders[index].previsionDeliveryDate=value
                commit('setOrders', state.orders)
            }
            
        },
        async setGoodProposalItemFields({ commit }, payload) {
            const { resourceUid, index, type ,callback} = payload
            const goodProposalItem = await getGoodProposalItem(resourceUid)
            if(callback){
                callback(goodProposalItem)
            }            
        },
        deleteEvent({ commit }, payload) {
            commit("deleteEvent", payload)
        },
        setOrderCommissions({ commit }, payload){
            commit("setOrderCommissions", payload)
        },
        deleteOrderCommission({ commit }, payload){
            commit("deleteOrderCommission", payload)
        },
        async selectGoodImage({ commit }, payload){
            const { productUid ,callback} = payload
            console.log("payload ",payload)
            const goodImage = await selectGoodImage(productUid)
            if(callback){
                callback(goodImage)
            } 
        },
        setselectedGoodImage({ state, commit }, payload) {
            const { index, goodImage} = payload
            state.orders[index].orderAssets[0].config.selectedGoodImage= goodImage
        },
        updateOrderItem({ commit }, payload) {
            const { orderItemResourceUid, previsionDeliveryDate,color,effectiveDeliveryDate } = payload;
            getOrderItem(orderItemResourceUid)
                .then( orderItem => {
                      updateOrderItem(orderItem.data, previsionDeliveryDate, orderItemResourceUid,color,effectiveDeliveryDate)
                        .then(updatedOrderItem => {
                            console.log("put res ", updatedOrderItem.data);
                        })
                        .catch(e => {
                            console.error(e);
                        });
                })
                .catch(e => {
                    console.error(e);
                });
        },
        updateOrderDateItem({ commit }, payload) {
            const { orderItemResourceUid, previsionDeliveryDate } = payload;
            getOrderItem(orderItemResourceUid)
                .then( orderItem => {
                    updateDateOrderItem(orderItem.data, previsionDeliveryDate, orderItemResourceUid)
                        .then(updatedOrderItem => {
                            console.log("put res ", updatedOrderItem.data);
                        })
                        .catch(e => {
                            console.error(e);
                        });
                })
                .catch(e => {
                    console.error(e);
                });
        },
        getOrderStatus({ commit }, payload) {
            const { filter, callback } = payload;
            getOrderStatusProgress(filter)
              .then((res) => {
                callback(res);
              })
              .catch((e) => console.error(e));
        },
        async getOrder({ state,commit }, payload){
            const { orderResourceUid, callback } = payload;            
            
            const order = await getOrder(orderResourceUid)
        
            if(callback){
                callback(order)
            } 
        },
        createProductCategoryClass({ commit },payload) {
            const { parentResourceUid,body ,callback} = payload
            createProductCategoryClass(parentResourceUid,body)
              .then((response) => {
                callback(response);
              })
              .catch((err) => {
                console.error(err);
                Notify.create({
                  message: err,
                  color: "negative",
                });
            });
        },
        updateOrderStatus({ commit }, payload) {
            const { orderResourceUid, status } = payload;
            const orderStatus = {
                "status": status
            }
            updateOrderStatus(orderResourceUid, orderStatus)
              .then((response) => {
                console.log("response ", response);
                //commit()
              })
              .catch(e => {
                console.error(e);
            });
        },
         getAlertReasons({ commit }, payload){
         
            const {callback} = payload
            getAlertReasons().then(res => {
                callback(res);
            })
            .catch(e => console.error(e))

           
        },
        getOrderAlertList({ commit }, payload) {
            const { filter, callback } = payload
           
            getOrderAlertList(filter)
                .then(res => {
                   const alertList=  buildAlertList(res)
                    commit('setOrderAlertList',alertList)
                    if (callback){
                        callback(alertList);
                    }
                })
                .catch(e => console.error(e))
        },
        async addPartieToOrders({ state, dispatch }, payload) {
            const {orders,newParty,roleType} = payload
            for(const or of orders){
                const currentOrder = await getOrderOrchestration(or.orderid)
                const cloneCurrentOrder =deepClone(currentOrder)
                const cloneNewParty =deepClone(newParty)
                 addPartieToOrders(cloneCurrentOrder,cloneNewParty,roleType)
            }
            Notify.create({
                message: i18n.global.t("order.messages.updateSuccess"),
                color: "positive",
            })
        },
        setNewParty({commit}, payload) {
            commit('setNewParty', payload);
        },
        setDeliveryFilter({ commit }, payload) {
            const {filter } = payload
            commit("setDeliveryFilter", filter);
        },
        setVisibleColumns({ commit }, payload) {
            const {visibleColumns } = payload
            commit("setVisibleColumns", visibleColumns);
        },
        recoverState({ commit }, payload) {
            const orderResourceUid = localStorage.getItem(globalConfig.storageKeys.orderModule)
            if (orderResourceUid) {
                const orderuid = JSON.parse(orderResourceUid).resourceUid
                if (orderuid){
                    store.dispatch("orderModule/initOrderEditMode", {
                        resourceUid: orderuid
                    })
                }
            }
        },
        setPreviousRoute({ commit }, payload) {
            const {previousRoute } = payload
            commit("setPreviousRoute", previousRoute);
        },
        sendMailWithDocument({ commit }, payload) {    
            const {emailSendList,attachment,fileReference } = payload  
            sendMailWithDocument(emailSendList, attachment,fileReference).then(res => {
                        Notify.create({            
                            message: i18n.global.t("order.messages.mailsended"),            
                            color: "positive"        
                        })     
                        })     
                        .catch(e => console.error(e))
                    },
        getScannedDoc: ({ commit }, payload) => {
            const { request, callback } = payload
        
        },
    },
    mutations: {
        setAssetReadOnly(state, payload) {
            const { readOnly } = payload
            const { asset } = state
            asset.config.readOnly = readOnly;
        },
        setOrderVariablesVisible(state, payload){
            state.variablesVisible = payload;
        },
        setOrderDetailPreconditionVisible(state, payload){
            state.preConditionDetailVisible = payload;
        },
        setOrderDetailPrecondition(state, payload){
            state.preConditionDetail = payload;
        },
        setOrderStatusHistoryVariablesSelected(state, payload){
            state.statusHistoryVariableSelected = payload;
        },
        setOrder(state, payload) {
          state.order = payload
        },
        setChannel(state, payload) {
            state.order.channel = payload
        },
        setOrderItem(state, payload) {
            state.orderItem = payload
        },
        toggleShow(state) {
            state.showAlert = !state.showAlert
        },
        setOrderAssociatedParty(state, payload){
            const {party, role, objectType} = payload
            state.order.orderParties[0].orderParty.party.resourceUid = party
            state.order.orderParties[0].orderParty.party.objectType = objectType
            state.order.orderParties[0].orderParty.role.resourceUid = role
        },

        setSupplier(state, payload){
            const { asset ,orders } = state
            const {party, objectType, role, name,orderIndex} = payload
            if (asset){
                asset.config.formBind.supplier.party = { resourceUid: party, objectType: objectType}
                asset.config.formBind.supplier.role = { resourceUid: role }
                asset.config.formBind.supplier.name = name
                asset.config.supplierPartyName = name
            }
            if(orderIndex !== null && orderIndex !== undefined){
                orders[orderIndex].orderAssets[0].config.formBind.supplier.party = { resourceUid: party, objectType: objectType}
                orders[orderIndex].orderAssets[0].config.formBind.supplier.role = { resourceUid: role }
                orders[orderIndex].orderAssets[0].config.formBind.supplier.name = name
                orders[orderIndex].orderAssets[0].config.supplierPartyName = name
                orders[orderIndex].orderAssets[0].supplier = {systemUid: 'odm-order'}
                orders[orderIndex].orderAssets[0].supplier.party = { resourceUid: party, objectType: objectType,systemUid: 'odm-party'}
                orders[orderIndex].orderAssets[0].supplier.role = { resourceUid: role,systemUid: 'odm-order' }
                console.log(orders[orderIndex].orderAssets[0].config.supplierPartyName)
            }
        },
        setNetwork(state, payload) {
            if (state.order?.orderNetworks || state.order?.orderNetworks == "") {
                state.order.orderNetworks.network.resourceUid = payload
            }
        },
        setNetworkNode(state, payload) {
            if (state.order?.orderNetworkNodes || state.order?.orderNetworkNodes == "") {
                state.order.orderNetworkNodes.networkNode.resourceUid = payload
            }
        },
        setCommercial(state, payload) {
            if (state.order?.assignedSalesPerson || state.order?.assignedSalesPerson == "") {
                state.order.assignedSalesPerson = payload.value
            }
        },
        setPlaningEvent(state, payload) {
          state.planningDelevry=payload
            
        },
        selectedDayEvent(state, payload) {
            state.selectedDayEvent=payload   
          },

        changeDateEvent(state, payload) {
            state.selectedEventPlanning.actionStartDate =  (moment((payload)).format("DD/MM/YYYY"));
            state.selectedEventPlanning.actionStartHour=  (moment(payload).format("HH:mm"));
            state.selectedEventPlanning.actionEndDate=  (moment(payload).format("DD/MM/YYYY"));
            state.selectedEventPlanning.actionEndHour =  (moment(payload).add(2, 'hour').format("HH:mm"));
          },
        
        setInitial(state) {
            state.orders = []
            state.mode = OrderModeStatus.CREATE
            state.index = -1
            state.order = {}
            state.orderItem = {}
            delete state.asset
            state.orderFinancingModule.financing = {}
        },
        setAsset(state, payload) {
            console.log("setAsset")
            state.asset = payload;
        },
        setMode(state, payload) { 
            state.mode = payload
        },
        setIndex(state, payload) {
            state.index = payload
        },
        setOrders(state, payload) {
            state.orders = payload
        },
        updateProposalAssetItem(state, payload) {
            const { proposalAssetItem, index } = payload
            const proposalAsset = state.orders[index].orderAssets[0]
            if (proposalAsset.proposalAssetItems) {
                const proposalAssetItemStateIndex = proposalAsset.orderAssetComponents.indexOf(proposalAssetItem)
                proposalAsset.orderAssetComponents[proposalAssetItemStateIndex] = proposalAssetItem
            }
        },
        removeProposalAssetItem(state, payload) {
            const { proposalAssetItem, index } = payload
            const proposalAsset = state.orders[index].orderAssets[0]
            proposalAsset.orderAssetComponents = (proposalAsset.orderAssetComponents || []).filter((item: any) => {
                    const compareResourceUid = (!!item.resourceUid && !!proposalAssetItem.resourceUid && item.resourceUid === proposalAssetItem.resourceUid)
                    const compareReference = (item.config.reference && proposalAssetItem.config.reference && item.config.reference === proposalAssetItem.config.reference)
                    const isRemoved = compareResourceUid || compareReference
                    return !isRemoved
                }
            )
        },
        setProposalAssetByIndex(state, payload) {
            const { proposalAsset } = payload
            state.asset = proposalAsset;
        },
       
        setOrderItemStatus(state, payload) {
            state.orderItem.status.resourceUid = payload
        },
        setPreConditionValid(state, payload) {
            state.preConditionValid = payload
        },
        setOrderStatus(state, payload) {
            state.order.status.resourceUid = payload
        },
        setOrderAlertList(state, payload) {
            state.alertList = payload
        },
        setOrderGatheringInfo(state, payload) {
            state.order.status.resourceUid = payload
        },
        setHistoriesStep(state, payload) {
            state.historiesStep = payload
        },
        setSelectedAssetFrom(state, payload) {
            state.selectedAssetFrom = payload
        },
        setSlectedProductAsset(state, payload) {
            state.selectedProductAsset = payload
        },
        updateTempSelectedOrderGoods(state, payload) {
            state.tempSelectedOrderGoods = payload;
        },
        setGatheringProcessOrder(state, payload) {
            state.gatheringProcessOrder = payload;
        },
        setAssetDeliveryModel(state, payload) {
            state.assetDeliveryModel = payload;
        },
        deleteEvent(state, payload) {
            const { index } = payload
            state.planningDelevry?.splice(index-1, 1);
        },
        setAlertList(state, payload) {
            state.order.alertList = payload;
          },
        setorderSubvention(state, payload) {
            state.order.orderSubsidies = payload;
          },
        SetSelectedSearchOrder(state, payload) {
            state.selectedSearchOrder = payload;
        },
        SetSelectedSearchOrderItem(state, payload) {
            state.selectedSearchOrderItem = (payload);
        },
        setSelectedEventPlanning(state, payload) {
            state.selectedEventPlanning = payload;
        },
        setOrderCommissions(state, payload) {
            state.order.orderCommissions = payload;
        },
        deleteOrderCommission(state, payload) {
            const { index } = payload
             if (index !== -1) {
                state.order.orderCommissions.splice(index, 1);
            }
        },
        setNewParty(state, payload) {
            state.newParty = payload;
        },
        setDeliveryFilter(state, payload) {
            state.deliveryFilter = payload;
        }
        ,
        setVisibleColumns(state, payload) {
            state.visibleColumns = payload;

        },
        setPreviousRoute(state, payload) {
            state.previousRoute = payload;

        }
        
    },
    modules: {
        orderFinancingModule: orderFinancingModule,
    },
}

export default orderModule;
